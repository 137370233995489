import React, { useState, useEffect } from 'react';
import { Avatar, IconButton, Box, Flex, useToast } from '@chakra-ui/react';
import { FiCamera, FiUpload, FiX } from 'react-icons/fi';
import Cropper from 'react-easy-crop';
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { uploadToS3 } from "../store/s3/s3Slice";

interface AvatarUploaderProps {
    name: string;
    avatarUrl: string;
    onImageUpload: (url: string) => void;
}

type CroppedArea = { x: number; y: number; width: number; height: number };

const AvatarUploader: React.FC<AvatarUploaderProps> = ({ name, avatarUrl, onImageUpload }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [croppedArea, setCroppedArea] = useState<CroppedArea | null>(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [preview, setPreview] = useState<string | null>(avatarUrl);
    const [blobUrl, setBlobUrl] = useState<string | null>(null); // State to store blob URL
    const dispatch = useAppDispatch();
    const toast = useToast();
    const loading = useAppSelector((state) => state.s3.loading);

    useEffect(() => {
        if (selectedFile) {
            // Create the blob URL once when file is selected
            const url = URL.createObjectURL(selectedFile);
            setBlobUrl(url);
            return () => {
                // Cleanup the blob URL after component unmounts or when the selectedFile changes
                if (url) {
                    URL.revokeObjectURL(url);
                }
            };
        }
    }, [selectedFile]);

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
            setIsEditing(true);
        }
    };

    const handleCropComplete = (_: unknown, croppedAreaPixels: any) => {
        setCroppedArea(croppedAreaPixels);
    };

    const uploadCroppedImage = async () => {
        if (!selectedFile || !croppedArea) return;

        try {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const image = await new Promise<HTMLImageElement>((resolve, reject) => {
                const img = new Image();
                img.src = blobUrl || ''; // Use the cached blob URL here
                img.onload = () => resolve(img);
                img.onerror = reject;
            });

            const { width, height } = croppedArea;
            canvas.width = width;
            canvas.height = height;
            ctx?.drawImage(image, croppedArea.x, croppedArea.y, width, height, 0, 0, width, height);

            canvas.toBlob(async (blob) => {
                if (blob) {
                    const croppedFile = new File([blob], selectedFile.name, { type: 'image/jpeg' });
                    const uploadedUrl = await dispatch(uploadToS3(croppedFile)).unwrap();
                    onImageUpload(uploadedUrl);
                    setPreview(uploadedUrl);
                    toast({
                        title: 'Image uploaded successfully!',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            }, 'image/jpeg');
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            toast({
                title: 'Error uploading image.',
                description: errorMessage,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsEditing(false);
            setSelectedFile(null);
        }
    };

    return (
        <Flex justifyContent="center" alignItems="center" position="relative">
            <Box position="relative">
                <Avatar size="xl" src={preview || undefined} />
                <IconButton
                    position="absolute"
                    bottom={0}
                    right={0}
                    aria-label="Change Avatar"
                    icon={<FiCamera />}
                    onClick={() => document.getElementById('avatar-upload')?.click()}
                    borderRadius="full"
                    bg="black"
                    color="white"
                    _hover={{ bg: "gray.600" }}
                />
            </Box>
            <input
                id="avatar-upload"
                type="file"
                accept="image/*"
                onChange={handleFileSelect}
                style={{ display: 'none' }}
            />
            {isEditing && (
                <Box
                    position="fixed"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    backgroundColor="rgba(0, 0, 0, 0.5)"
                    zIndex="modal"
                >
                    <Flex justifyContent="center" alignItems="center" height="100%">
                        <Box
                            position="relative"
                            width="90%"
                            maxWidth="500px"
                            height="400px"
                            bg="white"
                            p={4}
                            borderRadius="md"
                            zIndex="popover"
                        >
                            <Cropper
                                image={blobUrl || ''} // Use the cached blob URL here
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onCropComplete={handleCropComplete}
                                onZoomChange={setZoom}
                            />
                            <Flex
                                position="absolute"
                                bottom="20px"
                                right="20px"
                                gap={4}
                            >
                                <IconButton
                                    aria-label="Cancel"
                                    icon={<FiX />}
                                    onClick={() => setIsEditing(false)}
                                    borderRadius="full"
                                    bg="black"
                                    color="white"
                                    _hover={{ bg: "gray.600" }}
                                />
                                <IconButton
                                    aria-label="Upload"
                                    icon={<FiUpload />}
                                    onClick={uploadCroppedImage}
                                    isLoading={loading}
                                    borderRadius="full"
                                    bg="black"
                                    color="white"
                                    _hover={{ bg: "gray.600" }}
                                />
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            )}
        </Flex>
    );
};

export default AvatarUploader;
