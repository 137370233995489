import {
    Flex,
    Grid,
    Input,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";

import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { News } from "../../../types";
import { RootState } from "../../../store/store";
import { fetchBookmarks } from "../../../store/user/userSlice";
import { useIntl } from "react-intl";
import { isEmptyString } from "../../../utils/stringUtils";
import NewsCard from "../../../components/NewsCard/NewsCard";
import EmptyState from "../../../components/EmptyState";

interface BookmarksProps {}

const Bookmarks: FC<BookmarksProps> = () => {
    const dispatch = useAppDispatch();
    const bookmarks = useAppSelector((state: RootState) => state.user.bookmarks);
    const intl = useIntl();

    const [searchText, setSearchText] = useState<string>("");
    const [filteredBookmarks, setFilteredBookmarks] = useState<News[]>([]);

    // Fetch bookmarks only on the first render
    useEffect(() => {
        if (!bookmarks || bookmarks.length === 0) {
            dispatch(fetchBookmarks());
        }
    }, [dispatch]);

    // Filter bookmarks when searchText or bookmarks change
    useEffect(() => {
        if (!bookmarks) return;

        const filtered = isEmptyString(searchText)
            ? bookmarks
            : bookmarks.filter((news) =>
                news.title?.toLowerCase().includes(searchText) ||
                news.content.toLowerCase().includes(searchText)
            );
        setFilteredBookmarks(filtered);
    }, [bookmarks, searchText]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value.toLowerCase());
    };

    return (
        <>
            {!bookmarks || bookmarks.length === 0 ? (
                <EmptyState id="no_bookmarks" defaultMessage="You don't have any Bookmarks yet..." />
            ) : (
                <>
                    <Flex justifyContent="space-between" alignItems="center" mb={5}>
                        <Input
                            placeholder={intl.formatMessage({ id: "search_news", defaultMessage: "Search posts..." })}
                            value={searchText}
                            onChange={handleSearch}
                            width="300px"
                        />
                    </Flex>
                    <Grid
                        templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
                        templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
                        gap="24px"
                    >
                        {filteredBookmarks.map((n) => (
                            <NewsCard
                                key={n.id} // Assuming `id` exists in News type
                                news={n}
                                noOfLines={2}
                                imageHeight="200"
                                fontWeight="normal"
                            />
                        ))}
                    </Grid>
                </>
            )}
        </>
    );
};

export default Bookmarks;
