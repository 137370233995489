import React, {FC, useState} from "react";
import {
    Card,
    Grid,
    Avatar,
    Box,
    Text,
    useColorModeValue,
    Editable,
    EditablePreview,
    Input,
    EditableInput, useEditableControls, ButtonGroup, IconButton, Flex
} from "@chakra-ui/react";

import avatar1 from "../../../assets/img/avatars/avatar1.png";
import {User} from "../../../types";
import {CheckIcon, CloseIcon, EditIcon} from "@chakra-ui/icons";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import useCustomToast from "../../../hooks/useCustomToast";
import {updateUserDetails} from "../../../store/user/userSlice";
import AvatarUploader from "../../../components/AvatarUploader";

interface HeaderProps {
    user: User;
}

const Header: FC<HeaderProps> = ({ user }) => {
    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const textColor = useColorModeValue('light.text', 'dark.text');
    const [editedBio, setEditedBio] = useState(user.bio);

    const handleBioEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedBio(event.target.value);
    };

    const handleProfilePicChange = (url: string) => {
        dispatch(updateUserDetails({ profilePicUrl: url }));
    }

    const dispatch = useAppDispatch();
    const toast = useCustomToast();

    function EditableControls() {
        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
        } = useEditableControls()

        return isEditing ? (
            <ButtonGroup justifyContent='center' size='sm'>
                <IconButton icon={<CheckIcon />} aria-label="Submit" {...getSubmitButtonProps({
                    onClick: () => {
                        dispatch(updateUserDetails({ bio: editedBio }))
                            .unwrap()  // Unwraps the promise to handle errors correctly
                            .then(() => {
                                toast({
                                    title: "Success!",
                                    description: "Bio updated successfully.",
                                    status: "success",
                                    position: "bottom-right"
                                });
                            })
                            .catch((err: string) => {
                                toast({
                                    title: "Error!",
                                    description: err || "Failed to update bio.",
                                    status: "error",
                                    position: "bottom-right"
                                });
                            });
                    }
                })} />
                <IconButton icon={<CloseIcon />} aria-label="Cancel" {...getCancelButtonProps()} />
            </ButtonGroup>
        ) : (
            <Flex justifyContent='center'>
                <IconButton size='sm' icon={<EditIcon />} aria-label="Edit" {...getEditButtonProps()} />
            </Flex>
        )
    }

    return (
        <Card id="profile" p={4} bgColor={bgColor}>
            <Box p={2}>
                <Grid
                    templateColumns={{ base: "1fr", md: "auto 1fr" }}
                    gap={4}
                    alignItems="center"
                >
                    <AvatarUploader
                        name={user.name}
                        avatarUrl={user.profilePicUrl ? user.profilePicUrl : ''}
                        onImageUpload={handleProfilePicChange}
                    />
                    <Box lineHeight={1} textAlign={{ base: "center", md: "left" }}>
                        <Text fontSize="2xl"  textAlign="center" fontWeight="medium">
                            {user.name}
                        </Text>
                        <Editable
                            textAlign="center"
                            defaultValue={user.bio}
                            fontSize="md"
                            fontWeight="medium"
                            color={textColor}
                            isPreviewFocusable={false}
                        >
                            <EditablePreview />
                            <Input as={EditableInput} onChange={handleBioEditChange} />
                            <EditableControls />
                        </Editable>
                    </Box>
                </Grid>
            </Box>
        </Card>
    );
};

export default Header;
