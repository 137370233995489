import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
    Box,
    Flex,
    Text,
    Input,
    Button,
    FormControl,
    FormLabel,
    Stack,
    useBreakpointValue,
    FormErrorMessage,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import useCustomToast from "../../hooks/useCustomToast";
import { signUpUser } from "../../store/auth/authSlice";
import { validateEmail, validatePassword } from "../../utils/stringUtils";

export const SignUp = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState<string | null>(null);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showToast = useCustomToast();
    const formSpacing = useBreakpointValue({ base: 4, md: 6 });

    // Validation for sign-up form
    const handleSignUpClick = async () => {
        // Clear previous errors
        setEmailError(null);
        setPasswordError(null);

        // Validate form inputs
        if (!email || !password || !confirmPassword) {
            setPasswordError("allFieldsRequired");
            return;
        }
        if (!validateEmail(email)) {
            setEmailError("invalidEmail");
            return;
        }
        if (password !== confirmPassword) {
            setPasswordError("passwordMismatch");
            return;
        }
        const passwordValidationError = validatePassword(password);
        if (passwordValidationError) {
            setPasswordError(passwordValidationError);
            return;
        }

        // Attempt sign-up
        try {
            await dispatch(signUpUser({ email, password })).unwrap();
            showToast({
                title: "Success!",
                description: "Account created successfully.",
                status: "success",
                position: "bottom-right",
            });
            navigate("/verify", { state: { email } });
        } catch (error: any) {
            const errorMsg = error.message || "Sign up failed.";
            showToast({
                title: "Error!",
                description: errorMsg,
                status: "error",
                position: "bottom-right",
            });
        }
    };

    return (
        <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
        >
            <Flex
                width={{ base: "100%", md: "90%", lg: "80%" }}
                maxWidth="1200px"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
                bg="white"
                overflow="hidden"
            >
                {/* Left Side: Sign-Up Form */}
                <Box flex="1" padding="40px" width={"100%"}>
                    <Text fontSize="2xl" fontWeight="bold" mb={8} textAlign={"left"}>
                        <FormattedMessage id="signUp" defaultMessage="Sign Up" />
                    </Text>
                    <Stack spacing={formSpacing}>
                        {/* Email Field */}
                        <FormControl id="email" isInvalid={!!emailError}>
                            <FormLabel>
                                <FormattedMessage id="emailAddress" defaultMessage="Email Address" />
                            </FormLabel>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                focusBorderColor="grey.400"
                            />
                            {emailError && (
                                <FormErrorMessage>
                                    <FormattedMessage id={emailError} />
                                </FormErrorMessage>
                            )}
                        </FormControl>

                        {/* Password Field */}
                        <FormControl id="password" isInvalid={!!passwordError}>
                            <FormLabel>
                                <FormattedMessage id="password" defaultMessage="Password" />
                            </FormLabel>
                            <Input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                focusBorderColor="grey.400"
                            />
                        </FormControl>

                        {/* Confirm Password Field */}
                        <FormControl id="confirm-password" isInvalid={!!passwordError}>
                            <FormLabel>
                                <FormattedMessage id="confirmPassword" defaultMessage="Confirm Password" />
                            </FormLabel>
                            <Input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                focusBorderColor="grey.400"
                            />
                            {passwordError && (
                                <FormErrorMessage>
                                    <FormattedMessage id={passwordError} />
                                </FormErrorMessage>
                            )}
                        </FormControl>

                        {/* Sign-Up Button */}
                        <Button
                            bg="black"
                            color="white"
                            size="lg"
                            _hover={{ bg: "gray.800" }}
                            onClick={handleSignUpClick}
                        >
                            <FormattedMessage id="signUp" defaultMessage="Sign Up" />
                        </Button>
                    </Stack>

                    {/* Login Redirect */}
                    <Text textAlign="left" mt={8} fontSize="sm">
                        <FormattedMessage id="alreadyHaveAccount" defaultMessage="Already have an account?" />
                    </Text>
                    <Text
                        fontWeight="bold"
                        textAlign={"left"}
                        fontSize="sm"
                        cursor="pointer"
                        textDecoration="underline"
                        onClick={() => navigate("/login")}
                    >
                        <FormattedMessage id="loginHere" defaultMessage="Login here" />
                    </Text>
                </Box>

                {/* Right Side: Info Section */}
                <Box
                    flex="1"
                    bg="black"
                    padding="40px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="lg"
                >
                    <Stack spacing={4} mt={20} mb={20}>
                        <Text fontSize="xl" fontWeight="bold" color="white" textAlign="left">
                            <FormattedMessage
                                id="exploreNews"
                                defaultMessage="Explore the world of news."
                            />
                        </Text>
                        <Text color="white" textAlign="left">
                            <FormattedMessage
                                id="signupDetails"
                                defaultMessage="Sign up to stay updated with personalized news."
                            />
                        </Text>
                    </Stack>
                </Box>
            </Flex>
        </Flex>
    );
};
