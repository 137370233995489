import React, {useEffect, useState} from 'react';
import {Box, Divider, Flex} from '@chakra-ui/react';
import { AuthUser } from "aws-amplify/auth";
import TopStories from "./TopPicksCarousel";
import LatestNews from "./LatestNews";
import Headlines from "./Headlines";
import NewsDisplay from "./NewsDisplay";

interface HomeProps {
    user?: AuthUser
    signOut?: any
}

const Home = ({user, signOut}: HomeProps) => {
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        setRefreshKey((prev) => prev + 1);
    }, []);


    return  (
        <>
            <Flex justifyContent="space-between" gap="2%" margin="auto" width="100%" flexDirection={{ base: 'column', md: 'row' }}>
                <Box width={{ base: "100%", md: "80%" }}>
                    <Headlines />
                </Box>
                <Box width={{ base: "100%", md: "20%" }}>
                    <LatestNews />
                </Box>
            </Flex>
            {/*<TopStories />*/}
            <Divider borderColor="gray.300"  mt={10}/>
            <NewsDisplay key={refreshKey}/>
        </>
    );
}

export default Home;