import React, { useState } from "react";
import {
    Box,
    Flex,
    Text,
    Input,
    Button,
    Icon,
    Stack,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Divider,
    useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import useCustomToast from "../../hooks/useCustomToast";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { confirmSignUpUser, regenerateVerificationCode } from "../../store/auth/authSlice";
import {FormattedMessage} from "react-intl";
import {isNumberAndLengthEqualTo} from "../../utils/stringUtils";

export const Verification = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email;
    const dispatch = useAppDispatch();
    const showToast = useCustomToast();

    const [verificationCode, setVerificationCode] = useState("");
    const [error, setError] = useState("");

    const handleVerification = () => {
        if(!isNumberAndLengthEqualTo(verificationCode, 6)) {
            setError("invalidCode");
            return;
        }
        setError("");

        dispatch(confirmSignUpUser({ username: email, code: verificationCode }))
            .unwrap()
            .then(() => {
                showToast({
                    title: "Success!",
                    description: "Your email has been verified successfully.",
                    status: "success",
                    position: "bottom-right",
                });
                navigate("/login");
            })
            .catch((error: { message: string }) => {
                showToast({
                    title: "Error",
                    description: error.message || "Verification failed. Please try again.",
                    status: "error",
                    position: "bottom-right",
                });
            });
    };

    const handleRegenerateCode = () => {
        dispatch(regenerateVerificationCode({ email }))
            .unwrap()
            .then(() => {
                showToast({
                    title: "Success!",
                    description: "A new verification code has been sent to your email.",
                    status: "success",
                    position: "bottom-right",
                });
            })
            .catch((error: { message: string }) => {
                showToast({
                    title: "Error",
                    description: error.message || "Failed to resend verification code.",
                    status: "error",
                    position: "bottom-right",
                });
            });
    };

    const formSpacing = useBreakpointValue({ base: 4, md: 6 });

    return (
        <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
        >
            <Flex
                width={{ base: "100%", md: "90%", lg: "80%" }}
                maxWidth="1200px"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
                bg="white"
                overflow="hidden"
            >
                {/* Left Side: Verification Form */}
                <Box flex="1" padding="40px" width={"100%"}>
                    <Text fontSize="2xl" fontWeight="bold" mb={8} textAlign="left">
                        <FormattedMessage id="enterVerificationCode" defaultMessage="Enter Verification Code" />
                    </Text>
                    <Stack spacing={formSpacing}>
                        <FormControl isInvalid={!!error}>
                            <FormLabel fontWeight="medium">
                                <FormattedMessage id="verificationCode" defaultMessage="Verification Code" />
                            </FormLabel>
                            <Input
                                type="text"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                maxLength={6}
                                focusBorderColor="grey.400"
                            />
                            <FormErrorMessage>
                                <FormattedMessage id={error}/>
                            </FormErrorMessage>
                        </FormControl>
                        <Button
                            bg="black"
                            color="white"
                            size="lg"
                            onClick={handleVerification}
                            _hover={{ bg: "grey.800" }}
                        >
                            <FormattedMessage id="verify" defaultMessage="Verify" />
                        </Button>
                        <Text color="gray.600" textAlign="center" fontSize="sm">
                            <FormattedMessage id="didntReceiveCode" defaultMessage="Didn't receive the code?" />
                        </Text>
                        <Button
                            variant="outline"
                            colorScheme="black"
                            onClick={handleRegenerateCode}
                        >
                            <FormattedMessage id="resendCode" defaultMessage="Resend Code" />
                        </Button>
                    </Stack>
                </Box>

                {/* Right Side: Info Section */}
                <Box
                    flex="1"
                    bg="black"
                    padding="40px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="lg"
                >
                    <Stack spacing={4} mt={20} mb={20}>
                        <Text fontSize="xl" fontWeight="bold" color="white" textAlign="left">
                            Looking for news around you?
                        </Text>
                        <Text color="white" textAlign="left">
                            See your news even if you are not a registered user.
                        </Text>
                    </Stack>
                </Box>
            </Flex>
        </Flex>
    );
};
