import fallbackImage from '../assets/img/empty_state.png';
import {Box, Text, Image, HStack} from "@chakra-ui/react";
import {FormattedMessage} from "react-intl"; // Replace with your custom icon if needed

interface EmptyStateProps {
    id?: string,
    defaultMessage?: string
}

const NoDataServer = ({id, defaultMessage}: EmptyStateProps) => {
    return (

            <HStack spacing={4} textAlign="center">
                <Box position="relative">
                    <Image src={fallbackImage} alt="No data"/>
                </Box>
                <Text fontSize="lg" fontWeight="bold">
                    <FormattedMessage id={id} defaultMessage={defaultMessage}/>
                </Text>
            </HStack>
    );
};

export default NoDataServer;
