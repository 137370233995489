import React, {useEffect, FC} from 'react';
import {
    Box,
    Image,
} from '@chakra-ui/react';
import {useAppDispatch} from "../hooks/useAppDispatch";
import {useAppSelector} from "../hooks/useAppSelector";
import {RootState} from "../store/store";
import {fetchCountries} from "../store/country/countrySlice";

import {Select, chakraComponents} from "chakra-react-select";
import {Country} from "../types";
import { useIntl } from "react-intl";

const customComponents = {
    Option: (props: any) => (
        <chakraComponents.Option {...props}>
            {props.data.icon} {props.children}
        </chakraComponents.Option>
    ),
};

interface CountryPickerProps {
    onChange: (country: Country) => void
    selectedCountryCode?: string
    onFocus?: () => void
    variant?: string
}

const CountryPicker: FC<CountryPickerProps> = ({selectedCountryCode, onChange, onFocus, variant }) => {
    const dispatch = useAppDispatch();
    const countries = useAppSelector((state: RootState) => state.country.countries);
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const intl = useIntl();
    const countryOptions = countries.map(country => ({
        value: country.cca2,
        label: country.name,
        icon: (
            <Image
                src={`https://flagcdn.com/16x12/${country.cca2.toLowerCase()}.png`}
                alt={`${country.name} flag`}
                mr={2}
                h={5}
                w={5}
            />
        ),
    }));

    useEffect(() => {
        if (locale && countries.length === 0) {
            dispatch(fetchCountries(locale));
        }
    }, [dispatch, locale]);

    const defaultCountry = countryOptions.find(
        (option) => option.value === selectedCountryCode
    ) || null;

    const handleChange = (selectedOption: any) => {
        const country = countries.find(
            (country) => country.cca2 === selectedOption?.value
        ) || null;
        if(country !== null) {
            onChange(country);
        }
    };

    const chakraStyles = {
        dropdownIndicator: (provided: any) => ({
            ...provided,
            display: 'none', // Hide the dropdown arrow
        }),
        placeholder: (provided: any) => ({
            ...provided,
            textAlign: 'left', // Ensure placeholder is left-aligned
        }),
    };

    return (
        <Box width="100%">
            <Select
                name="country"
                options={countryOptions}
                placeholder={intl.formatMessage({ id: "select_your_country", defaultMessage: "Select your country..." })}
                components={customComponents}
                onFocus={onFocus}
                defaultValue={defaultCountry}
                onChange={handleChange}
                chakraStyles={chakraStyles}
                variant={variant ? variant : "outline"}
                isClearable
            />
        </Box>
    );
};

export default CountryPicker;
