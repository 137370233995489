import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Card,
  Grid,
  Input,
  Select,
  Button,
  FormControl,
  FormLabel,
  useColorModeValue,
} from "@chakra-ui/react";
import {Tag, User} from "../../../../types";
import selectData from "./data/selectData";
import {useAppDispatch} from "../../../../hooks/useAppDispatch";
import {useAppSelector} from "../../../../hooks/useAppSelector";
import {RootState} from "../../../../store/store";
import {fetchCountries} from "../../../../store/country/countrySlice";
import {LanguageSelector} from "../../../../components/Header/LanguageSelector";
import {setLocale} from "../../../../store/locale/localeSlice";
import {FormattedMessage} from "react-intl";
import {capitalizeFirstLetter} from "../../../../utils/stringUtils";
import {updateUserDetails} from "../../../../store/user/userSlice";
import useCustomToast from "../../../../hooks/useCustomToast";
import CountryPicker from "../../../../components/CountryPicker";
import { SingleDatepicker } from "chakra-dayzed-datepicker";

interface BasicInfoProps {
  user: User;
}

const BasicInfo: FC<BasicInfoProps> = ({ user }) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState(user.email);
  const [username, setUsername] = useState(user.username);
  const [name, setName] = useState(user.name);
  const [birthDate, setBirthDate] = useState<Date>(user.dateOfBirth ? new Date(user.dateOfBirth) : new Date());
  const [gender, setGender] = useState(user.gender ? capitalizeFirstLetter(user.gender) : "Male");
  const [language, setLanguage] = useState(user.language);
  const [country, setCountry] = useState(user.country);
  const [tags, setTags] = useState<Tag[]>([]);
  const toast = useCustomToast();

  const handleTagSelection = (selectedTags: { label: string, value: Tag }[]) => {
    const tags1 = selectedTags.map((option) => option.value);
    setTags(tags1);
  }

  const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');

  const locale = useAppSelector((state: RootState) => state.locale.locale);

  useEffect(() => {
    dispatch(fetchCountries(locale));
  }, [dispatch]);

  const handleLocaleChange = (event: { target: { value: any; }; }) => {
    const newLocale = event.target.value;
    dispatch(setLocale(newLocale));
    localStorage.setItem('locale', newLocale);
    setLanguage(newLocale);
    window.location.reload();
  };


  const updateCurrentUserDetails = () => {
    const updatedUser = {
      email,
      username,
      name,
      birthDate: birthDate,
      gender: gender.toUpperCase(),
      language: locale,
      country,
      interests: tags
    };

    if(!country) {
        toast({
            title: "Error!",
            description: "Please select a country.",
            status: "error",
            position: "bottom-right"
        });
        return;
    }

    dispatch(updateUserDetails(updatedUser))
        .unwrap()  // Unwraps the promise to handle errors correctly
        .then(() => {
          toast({
            title: "Success!",
            description: "Details updated successfully.",
            status: "success",
            position: "bottom-right"
          });
        })
        .catch((err: string) => {
          toast({
            title: "Error!",
            description: err || "Failed to update details.",
            status: "error",
            position: "bottom-right"
          });
        });
  };

  return (
      <Card p={[2,4]} id="basic-info" bgColor={bgColor}>
        <Box p={[2,4]}>
          <Box mb={4}>
            <FormLabel fontSize="lg">
              <FormattedMessage id="basicInfo" defaultMessage="Basic Info"/>
            </FormLabel>
          </Box>
          <Grid
              templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
              gap={6}
          >
            <FormControl>
              <FormLabel><FormattedMessage id="name" defaultMessage="Name"/></FormLabel>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel><FormattedMessage id="username" defaultMessage="Username"/></FormLabel>
              <Input value={username} onChange={(e) => setUsername(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel><FormattedMessage id="gender" defaultMessage="Gender"/></FormLabel>
              <Select value={gender} onChange={(e) => setGender(e.target.value)}>
                {selectData.gender.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel><FormattedMessage id="birthDay" defaultMessage="Birth Day"/></FormLabel>
              <SingleDatepicker
                  name="date-input"
                  date={birthDate}
                  onDateChange={setBirthDate}
                  propsConfigs={{
                    triggerBtnProps: { width: '100%' }
                  }}
               triggerVariant={"default"}/>
            </FormControl>
            <FormControl>
              <FormLabel><FormattedMessage id="language" defaultMessage="Language"/></FormLabel>
              <LanguageSelector locale={locale} onLanguageChange={handleLocaleChange} />
            </FormControl>
            <FormControl>
              <FormLabel><FormattedMessage id="country" defaultMessage="Country"/></FormLabel>
              <CountryPicker selectedCountryCode={user?.country?.cca2} onChange={setCountry}/>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel><FormattedMessage id="emailAddress" defaultMessage="Email Address"/></FormLabel>
              <Input type="email" value={email} />
            </FormControl>
          </Grid>
          <Box mt={4} textAlign={{ base: "center", md: "right" }}>
            <Button colorScheme="blue" onClick={updateCurrentUserDetails}>
              <FormattedMessage id="updateDetails" defaultMessage="Update Details" />
            </Button>
          </Box>
        </Box>
      </Card>
  );
};

export default BasicInfo;
