import React from 'react';
import { AuthUser } from "aws-amplify/auth";
import HeadlinesCardView from "../../components/Feed/HeadlinesCardView";

interface BusinessProps {
    user?: AuthUser
    signOut?: any
}

const Entertainment = ({user, signOut}: BusinessProps) => (
    <>
        <HeadlinesCardView category="LIFESTYLE"/>
    </>
);

export default Entertainment;