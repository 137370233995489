import React, {useEffect} from 'react';
import {Box, Divider, Flex, SimpleGrid} from '@chakra-ui/react';
import { AuthUser } from "aws-amplify/auth";
import {useAppSelector} from "../../hooks/useAppSelector";
import {RootState} from "../../store/store";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {fetchUserHomeFeedNews} from "../../store/homeFeedNews/homeFeedNewsSlice";
import NewsCard from "../../components/NewsCard/NewsCard";

interface HomeProps {
    user?: AuthUser
    signOut?: any
}

const UserHome: React.FC = ({user, signOut}: HomeProps) => {
    const news = useAppSelector((state: RootState) => state.homeFeedNews.homeData);
    const dispatch = useAppDispatch();
    const locale = useAppSelector((state: RootState) => state.locale.locale);

    useEffect(() => {
        dispatch(fetchUserHomeFeedNews({ locale, page: 0 }));
    }, [dispatch, locale]);

    return (
        <>
            <Flex justifyContent="space-between" gap="2%" margin="auto" width="100%"
                  flexDirection={{base: 'column', md: 'row'}}>
                <Box width={{base: "100%"}}>
                    <Box mx="auto" px={{ base: 3, md: 0 }}>
                        <SimpleGrid
                            columns={{ base: 1, sm: 2, lg: 3 }}
                            spacing={5}
                            marginTop={5}
                        >
                            {news.map((item, index) => (
                                <Box key={index}>
                                    <NewsCard news={item} noOfLines={2} imageHeight="300" />
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>
                </Box>
            </Flex>
        </>
    )
};

export default UserHome;