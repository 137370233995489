import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {News} from "../../types";
import {startLoading, stopLoading} from "../loading/loadingSlice";
import axiosInstance from "../../utils/axiosInstance"; // Assuming you have a RootState type from your store

// Initial state for post slice
interface PostState {
    news: News | null;
    loading: boolean;
    error: string | null;
}

const initialState: PostState = {
    news: null,
    loading: false,
    error: null,
};

// Async thunk for saving the post as a draft
export const saveDraft = createAsyncThunk(
    'post/saveDraft',
    async (newsData: Partial<News>, { dispatch, rejectWithValue, getState }) => {
        try {
            dispatch(startLoading());
            const state = getState() as RootState;
            const accessToken = state.auth.idToken;
            const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
            const news = { ...newsData, country: newsData.country?.cca2, categoryDto: newsData.category };
            const response = await axiosInstance.post(`/api/v1/news/draft`, news, {headers: headers,});
            return response.data; // Return the saved post data
        } catch (error: any) {
            return rejectWithValue(error.response.data.message || 'Failed to save draft');
        } finally {
            dispatch(stopLoading());
        }
    }
);

// Async thunk for publishing the post
export const publishPost = createAsyncThunk(
    'post/publishPost',
    async (newsData: Partial<News>, { dispatch, rejectWithValue, getState }) => {
        try {
            dispatch(startLoading());
            const news ={ ...newsData, country: newsData.country?.cca2, categoryDto: newsData.category };
            const state = getState() as RootState;
            const accessToken = state.auth.idToken;
            const headers = accessToken ? { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' } : {};
            const response = await axiosInstance.post(`/api/v1/news/save`, news, {headers: headers,});
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data.message || 'Failed to publish post');
        } finally {
            dispatch(stopLoading());
        }
    }
);

export const fetchPostsOfUser = createAsyncThunk(
    'post/fetchPostsOfUser',
    async (userId: string, { dispatch, rejectWithValue }) => {
        try {
            dispatch(startLoading());
            const response = await axiosInstance.get(`/api/v1/news/user/${userId}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data.message || 'Failed to fetch posts');
        } finally {
            dispatch(stopLoading());
        }
    }
);

// Create the post slice
const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        // Add a reducer if you want to set post state manually
        setPost(state, action: PayloadAction<News>) {
            state.news = action.payload;
        },
        clearPost(state) {
            state.news = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveDraft.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(saveDraft.fulfilled, (state, action: PayloadAction<News>) => {
                state.loading = false;
                state.news = action.payload;
                state.error = null;
            })
            .addCase(saveDraft.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(publishPost.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(publishPost.fulfilled, (state, action: PayloadAction<News>) => {
                state.loading = false;
                state.news = action.payload;
                state.error = null;
            })
            .addCase(publishPost.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

// Export the action creators and reducer
export const { setPost, clearPost } = postSlice.actions;
export default postSlice.reducer;

// Selector to access the post state in your components
export const selectPost = (state: RootState) => state.post.news;
export const selectLoading = (state: RootState) => state.post.loading;
export const selectError = (state: RootState) => state.post.error;
