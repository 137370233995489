import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import {fetchAuthHeaders} from "../../utils/authHeaders";

interface FollowState {
    [userId: string]: boolean;
}

// Async Thunks for API calls
export const followUser = createAsyncThunk(
    "follow/followUser",
    async ({ userId, type }: { userId: string | number; type: 'USER' | 'ORGANISATION' },
           { dispatch, getState, rejectWithValue }) => {
        const headers = await fetchAuthHeaders(dispatch, getState);
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/user/follow`,
            { user_id: userId, type: type },
            {headers}
            );
        return { userId, data: response.data };
    }
);

export const unfollowUser = createAsyncThunk(
    "follow/unfollowUser",
    async (
        { userId, type }: { userId: string | number; type: 'USER' | 'ORGANISATION' },
        { dispatch, getState, rejectWithValue }) => {
        const headers = await fetchAuthHeaders(dispatch, getState);
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/user/unfollow`,
            { user_id: userId, type: type },
            {headers}
        );
        return { userId, data: response.data };
    }
);

// Slice
const followSlice = createSlice({
    name: "follow",
    initialState: {} as FollowState, // Initial state as an empty object
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(followUser.fulfilled, (state, action: PayloadAction<{ userId: string | number }>) => {
                state[action.payload.userId] = true; // Mark user as followed
            })
            .addCase(unfollowUser.fulfilled, (state, action: PayloadAction<{ userId: string | number }>) => {
                state[action.payload.userId] = false; // Mark user as unfollowed
            });
    },
});

export default followSlice.reducer;
