import React, {useEffect, useState} from "react";
import { Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { followUser, unfollowUser } from "../store/user/followSlice";
import {AppDispatch, RootState} from "../store/store";
import {FormattedMessage} from "react-intl";
import {useAppSelector} from "../hooks/useAppSelector";

interface FollowButtonProps {
    userOrOrgId: string | number;
    type: 'USER' | 'ORGANISATION';
    isFollowing: boolean | undefined;
}

const FollowButton: React.FC<FollowButtonProps> = ({ userOrOrgId, type, isFollowing }) => {
    const dispatch = useDispatch<AppDispatch>(); // Ensure you're typing your dispatch properly
    const idToken = useAppSelector((state) => state.auth.idToken);
    const [isFollowed, setIsFollowed] = useState<boolean>(isFollowing === true);

    useEffect(() => {
        setIsFollowed(isFollowing === true);
    }, [isFollowing]);

    const handleFollowToggle = () => {
        if (isFollowing) {
            dispatch(unfollowUser({ userId: userOrOrgId, type }));
        } else {
            dispatch(followUser({ userId: userOrOrgId, type }));
        }
        setIsFollowed(!isFollowed);
    };

    // Do not render if the user is not authenticated
    if (!idToken) {
        return null;
    }

    return (
        <Button
            size="sm"
            colorScheme="black"
            variant="outline"
            _hover={{ bg: "black", color: "white" }}
            onClick={handleFollowToggle}
        >
            {isFollowed ? <FormattedMessage id="following" defaultMessage="Following"/> : <FormattedMessage id="follow" defaultMessage="Follow" />}
        </Button>
    );
};

export default FollowButton;
