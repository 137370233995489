import {
    Box,
    Heading,
    Flex,
    Image,
    Text,
    useColorModeValue, Checkbox,
} from '@chakra-ui/react';


import {FormattedMessage} from "react-intl";
import React, {useEffect} from "react";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useAppSelector} from "../../hooks/useAppSelector";
import {RootState} from "../../store/store";
import {fetchUserDetails} from "../../store/user/userSlice";
import {User} from "../../types";

interface NewsLetterStepProps {
    handleStepDataChange: (updatedData: Partial<User>) => void
}

function NewsLetterStep({handleStepDataChange}: NewsLetterStepProps) {
    const dispatch = useAppDispatch();

    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const user = useAppSelector((state: RootState) => state.user.user);


    useEffect(() => {
        if (!user) {
            dispatch(fetchUserDetails(locale));
        }
    }, [dispatch, user]);

    return (
        <Flex justifyContent="center" alignItems="center">
            <Box
                textAlign="left"
            >
                <Image src={"https://wallpapers.com/images/featured/happy-snkhztxzr4v4ni71.jpg"}
                       w="100%"
                       maxH={300}
                       objectFit="cover"
                       mb={4}
                />
                <Heading size="lg" mb={4}>
                    <FormattedMessage id={"newsletter"} defaultMessage={"Stay Informed with NiSEE Community"}/>
                </Heading>
                <Box mb={6}>
                    <FormattedMessage id={"newsletter_description"}
                                      defaultMessage={"Subscribe to our weekly newsletter for top articles, trending discussions, community updates, and occasional news about DEV Challenges and events."}/>
                </Box>

                <Box
                    as="blockquote"
                    borderLeft="4px solid"
                    borderColor="darkred.400"
                    backgroundColor={useColorModeValue('light.blockquoteBackground', 'dark.blockquoteBackground')}
                    pl={4}
                    my={4}
                >

                    <Flex justifyContent="center" alignItems="start">
                        <Checkbox size={"lg"} onChange={(e) => {
                            handleStepDataChange({newsletter: e.target.checked})
                        }}/>
                        <Box ml={4} textAlign="justify">
                            <Heading size="md" mb={4}><FormattedMessage id={"news_letter_ack"}
                                                                        defaultMessage={"Yes, subscribe me to the weekly newsletter"}/></Heading>
                            <Text mt={2}>
                                <FormattedMessage id={"news_letter_privacy"}/>
                            </Text>
                        </Box>
                    </Flex>
                </Box>

            </Box>
        </Flex>
    );
}

export default NewsLetterStep;
