import {
    Flex,
    Grid,
    Input,
} from "@chakra-ui/react";
import React, {FC, useEffect, useState} from "react";

import {useAppSelector} from "../../../hooks/useAppSelector";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {User} from "../../../types";
import {RootState} from "../../../store/store";
import {fetchFollowers} from "../../../store/user/userSlice";
import UserBox from "../../../components/UserBox";
import { useIntl } from "react-intl";
import {useNavigate} from "react-router-dom";
import EmptyState from "../../../components/EmptyState";

interface FollowersProps {}

const Followers: FC<FollowersProps> = () => {
    const dispatch = useAppDispatch();
    const followers = useAppSelector((state: RootState) => state.user.followers);
    const intl = useIntl();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>("");
    const [filteredFollowers, setFilteredFollowers] = useState<User[]>([]);

    useEffect(() => {
        if (followers.length === 0) {
            dispatch(fetchFollowers());
        }
    }, [dispatch, followers.length]);


    useEffect(() => {
        if (searchText === "") {
            setFilteredFollowers(followers);
        } else {
            const filtered = followers.filter((user) =>
                user.name?.toLowerCase().includes(searchText) || user.username?.toLowerCase().includes(searchText)
            );
            setFilteredFollowers(filtered);
        }
    }, [followers, searchText]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        if (value === "") {
            setFilteredFollowers(followers);
        } else {
            const filtered = followers.filter((user) =>
                user.name?.toLowerCase().includes(value) || user.username?.toLowerCase().includes(value)
            );
            setFilteredFollowers(filtered);
        }
    };

    return (
        <>
                {followers.length === 0 ? (<></>) : (
                    <Flex justifyContent="space-between" alignItems="center"  mb={5}>
                        <Input
                            placeholder={intl.formatMessage({ id: "search_users", defaultMessage: "Search users..." })}
                            value={searchText}
                            onChange={handleSearch}
                            width="300px"
                        />
                    </Flex>
                )}
                    {followers.length === 0 ? (
                        <EmptyState id="no_followers" defaultMessage="You don't have any followers yet..."/>
                    ) : (
                        <Grid
                            key={filteredFollowers.length}
                            templateColumns={{ base: "repeat(2, 1fr)", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
                            templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
                            gap='24px'
                        >
                            {filteredFollowers.map((u) => (
                                <UserBox
                                    key={u.username}
                                    user={u}
                                    isSelected={false}
                                    onSelect={()=> {navigate(`/user/${u.id}`)}}
                                />
                            ))}
                        </Grid>
                    )}
        </>
    );
};

export default Followers;
