import {Box, Image, Text, useColorModeValue, useToast} from "@chakra-ui/react";
import { Source } from "../../types";

interface SourceBoxProps {
    source: Source;
    onSelectTag: (Source: Source) => void;
}

const SourceBox: React.FC<SourceBoxProps> = ({ source, onSelectTag }) => {
    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const borderColor = useColorModeValue("light.highlight", "dark.highlight");
    const textColor = useColorModeValue("inherit", "black");

    return (
        <Box
            as="button"
            borderWidth="1px"
            borderRadius="md"
            p={4}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            _hover={{ borderColor: borderColor, cursor: "pointer" }}
            borderColor={"gray.200"}
            bg={bgColor}
            width="100%"
            onClick={() => onSelectTag(source)}
        >
            {/* Icon */}
            <Image src={source.icon} alt={source.name} width={50} height={50} mr={4} />

            {/* Text Container */}
            <Box>
                {/* Name - Bold Text */}
                <Text fontWeight="bold" fontSize="md" color={textColor} textAlign="left">
                    {source.name}
                </Text>

                {/* Code - Small Text */}
                <Text fontWeight="light" fontSize="sm" color={textColor} textAlign="left">
                    @{source.code}
                </Text>
            </Box>
        </Box>

    );
};

export default SourceBox;
