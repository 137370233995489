import {
  Card, CardBody,
  Grid,
  useColorModeValue,
} from "@chakra-ui/react";
import React, {FC, useEffect} from "react";
import { useNavigate } from 'react-router-dom';

import {useAppSelector} from "../../../hooks/useAppSelector";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {fetchPostsOfCurrentUser} from "../../../store/userPosts/userPostSlice";
import {News} from "../../../types";
import NewsCard from "../../../components/NewsCard/NewsCard";
interface ProjectsProps {}

const Projects: FC<ProjectsProps> = () => {
  const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { posts } = useAppSelector((state) => state.userPosts);

  useEffect(() => {
    dispatch(fetchPostsOfCurrentUser());
  }, [dispatch]);

  return (
      <>
            <Grid
                templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
                templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
                gap='24px'>
              {posts.map((post: News) =>
             (
                 <NewsCard news={post} noOfLines={2} imageHeight={'200'} fontWeight={"normal"} />
             )
              )}
            </Grid>

      </>
  );
};

export default Projects;
