import { Box, Icon, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { FaCheckCircle } from "react-icons/fa";
import { Tag } from "../../types";

interface TagBoxProps {
    tag: Tag;
    isSelected: boolean;
    onSelectTag: (tag: Tag, isSelected: boolean) => void;
}

const TagBox: React.FC<TagBoxProps> = ({ tag, isSelected, onSelectTag }) => {
    // Light and dark mode background and border color values
    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const borderColor = useColorModeValue('light.highlight', 'dark.highlight');
    const selectedBorderColor = useColorModeValue('brand.600', 'brand.600');

    // Text color changes to black when selected in dark mode, stays the same in light mode
    const textColor = useColorModeValue('inherit', isSelected ? 'black' : 'white');

    const toast = useToast();

    const handleSelect = async () => {
        debugger;
        try {
            onSelectTag(tag, !isSelected); // Pass the event up to parent
        } catch (error) {
            toast({
                title: `Error selecting ${tag.name}`,
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
    };

    return (
        <Box
            as="button"
            borderWidth="1px"
            borderRadius="md"
            p={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            _hover={{ borderColor: borderColor, cursor: "pointer" }}
            bg={isSelected ? "brand.50" : bgColor}
            borderColor={isSelected ? selectedBorderColor : "gray.200"}
            onClick={handleSelect}
            width="100%"
        >
            <Box>
                <Text fontWeight="bold" color={textColor}>#{tag.code}</Text>
                {/* Smaller text for the posts */}
            {/*    <Text fontSize="sm" fontWeight="light" color={textColor}>
                    {tag.posts} <FormattedMessage id={"posts"} defaultMessage={"posts"} />
                </Text>*/}
            </Box>
            {/* Added margin-left (ml) to create more space between the text and checkbox */}
            {/*<Box ml={4}>*/}
            {/*    /!* Custom round checkbox *!/*/}
            {/*    <Box*/}
            {/*        width="20px"*/}
            {/*        height="20px"*/}
            {/*        borderRadius="50%"*/}
            {/*        borderWidth="2px"*/}
            {/*        borderColor={isSelected ? "red.500" : "gray.300"}*/}
            {/*        display="flex"*/}
            {/*        alignItems="center"*/}
            {/*        justifyContent="center"*/}
            {/*        bg={isSelected ? "red.500" : "transparent"}*/}
            {/*    >*/}
            {/*        {isSelected && <Icon as={FaCheckCircle} color="white" boxSize={3} />}*/}
            {/*    </Box>*/}
            {/*</Box>*/}
        </Box>
    );
};

export default TagBox;
