import React, {useState} from "react";
import {IconButton} from "@chakra-ui/react";
import {FiBookmark} from "react-icons/fi";
import {bookmarkNews, unbookmarkNews} from "../store/post/bookmarkSlice";
import {useAppDispatch} from "../hooks/useAppDispatch";
import {FaBookmark} from "react-icons/all";
import {useAppSelector} from "../hooks/useAppSelector";

interface BookmarkButtonProps {
    newsId: string | number;
    isBookmarked: boolean;
}

const BookmarkButton: React.FC<BookmarkButtonProps> = ({ newsId, isBookmarked }) => {
    const dispatch = useAppDispatch();
    const idToken = useAppSelector((state) => state.auth.idToken);

    const [isBookmarkedLocal, setIsBookmarkedLocal] = useState(isBookmarked);

    const handleBookmarkToggle = () => {
        if (isBookmarkedLocal) {
            dispatch(unbookmarkNews({ newsId }));
            setIsBookmarkedLocal(false);
        } else {
            dispatch(bookmarkNews({ newsId }));
            setIsBookmarkedLocal(true);
        }
    };

    // Do not render if the user is not authenticated
    if (!idToken) {
        return null;
    }

    return (
        <IconButton
            icon={isBookmarkedLocal ? <FaBookmark />: <FiBookmark/> }
            color={isBookmarkedLocal ? "yellow.500" : "gray.500"}
            colorScheme={isBookmarkedLocal ? "yellow" : "gray"}
            aria-label="Bookmark"
            variant={isBookmarkedLocal ? "subtle" : "ghost"}
            size="lg"
            rounded="full"
            onClick={handleBookmarkToggle}
        />
    );
};

export default BookmarkButton;
