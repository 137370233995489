import {useLocation, useMatch, useParams} from 'react-router-dom';
import React, {useEffect, useLayoutEffect, useState} from "react";
import {News, User} from "../../types";
import {
    Heading,
    Avatar,
    Box,
    Center,
    Text,
    useColorModeValue, SimpleGrid,
} from '@chakra-ui/react'
import axios from "axios";
import {startLoading, stopLoading} from "../../store/loading/loadingSlice";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import NewsCard from "../../components/NewsCard/NewsCard";
import FollowButton from "../../components/FollowButton";
import {useAppSelector} from "../../hooks/useAppSelector";
import {refreshIdToken} from "../../store/auth/authSlice";

function UserDetailsPage() {
    const { id } = useParams();
    const [user, setUser] = useState<User | null>(null);
    const [userPosts, setUserPosts] = useState<News[]>([]);
    const dispatch = useAppDispatch();
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);  // Scroll to top
    }, [location]);


    const isUserRoute = useMatch("/user/:id");
    const isOrganisationRoute = useMatch("/organisation/:id");

    const type = isUserRoute ? "user" : isOrganisationRoute ? "organisation" : null;

    const idToken = useAppSelector((state) => state.auth.idToken);
    const [isTokenInitialized, setIsTokenInitialized] = useState<boolean>(false);

    const fetchUserDetails = async () => {
        if (!isTokenInitialized) {
            return;
        }
        try {
            dispatch(startLoading());
            const headers = {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            }
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL || "http://localhost:8080/"}api/v1/${type}/${id}`, {headers}
            );
            const data: User = await response.data;
            setUser(data);
        } catch (err) {
            console.error(err instanceof Error ? err.message : "Unknown error occurred.");
        } finally {
            dispatch(stopLoading());
        }
    };

    const fetchUserPosts = async () => {
        if (!isTokenInitialized) {
            return;
        }

        try {
            await dispatch(refreshIdToken());
            const headers = {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            }
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080'}/api/v1/news/${type}/${id}`, {headers}
            );
            setUserPosts(response.data);
        } catch (error: any) {
            console.error(error.response.data.message || 'Failed to fetch posts');
        } finally {
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        const initializeAndFetchData = async () => {
            await dispatch(refreshIdToken());
            setIsTokenInitialized(true);
            await fetchUserDetails();
            await fetchUserPosts();
        };

        initializeAndFetchData();
    }, [dispatch, isTokenInitialized, idToken]);


    return (
        <Box minH="100vh" pt={8}>
            <Center py={6}>
                <Box
                    maxW={'100%'}
                    w={'full'}
                    bg={useColorModeValue('white', 'gray.900')}
                    boxShadow={'lg'}
                    rounded={'lg'}
                    p={6}
                    pt={16} // Add padding top to make space for Avatar
                    textAlign={'center'}
                    position="relative" // Required for absolute positioning
                >
                    {/* Avatar */}
                    <Avatar
                        size={'xl'}
                        src={user?.profilePicUrl}
                        position={'absolute'} // Position avatar outside the card
                        top={-12} // Move it upwards
                        left={'50%'} // Center horizontally
                        transform={'translateX(-50%)'} // Adjust for exact centering
                        border={'4px solid'} // Optional: Add border for a nice look
                        borderColor={useColorModeValue('white', 'gray.900')} // Matches card background
                    />

                    {user && (<Box
                        position="absolute"
                        top={4}
                        right={4}
                    >
                        <FollowButton userOrOrgId={user.id} type={isUserRoute ? 'USER' : 'ORGANISATION'} isFollowing={user.isFollowing} />
                    </Box>)}

                    {/* Content */}
                    <Heading fontSize={'2xl'} fontFamily={'body'} mt={2}>
                        {user?.name}
                    </Heading>
                    {isUserRoute && (
                        <Text fontWeight={600} color={'gray.500'} mb={4}>
                            @{user?.username}
                        </Text>
                    )}

                    <Text
                        textAlign={'center'}
                        color={useColorModeValue('gray.700', 'gray.400')}
                        px={3}
                    >
                        {user?.bio}
                    </Text>
                </Box>
            </Center>

            {/* Posts Section */}
            <Box mt={8} px={{ base: 4, md: 8 }}>
                <SimpleGrid
                    columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
                    spacing={6}
                >
                    {userPosts.map((item, index) => (
                        <Box key={index}>
                            <NewsCard news={item} noOfLines={2} imageHeight="300" />
                        </Box>
                    ))}
                </SimpleGrid>
            </Box>
        </Box>
    );
}

export default UserDetailsPage;
