import {
    Card, CardBody, CardHeader,
    Flex,
    Grid,
    Input,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import React, {FC, useEffect, useState} from "react";

import {useAppSelector} from "../../../hooks/useAppSelector";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {User} from "../../../types";
import {FormattedMessage} from "react-intl";
import {RootState} from "../../../store/store";
import {fetchFollowing} from "../../../store/user/userSlice";
import UserBox from "../../../components/UserBox";
import {useNavigate} from "react-router-dom";
import EmptyState from "../../../components/EmptyState";

interface FollowingUsersProps {}

const FollowingUsers: FC<FollowingUsersProps> = () => {
    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const dispatch = useAppDispatch();
    const following = useAppSelector((state: RootState) => state.user.following);
    const navigate = useNavigate();

    const [searchText, setSearchText] = useState<string>("");
    const [filteredFollowing, setFilteredFollowing] = useState<User[]>([]);

    useEffect(() => {
        if (following.length === 0) {
            dispatch(fetchFollowing());
        }
    }, [dispatch, following.length]);


    useEffect(() => {
        if (searchText === "") {
            setFilteredFollowing(following);
        } else {
            const filtered = following.filter((user) =>
                user.name?.toLowerCase().includes(searchText) || user.username?.toLowerCase().includes(searchText)
            );
            setFilteredFollowing(filtered);
        }
    }, [following, searchText]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        if (value === "") {
            setFilteredFollowing(following);
        } else {
            const filtered = following.filter((user) =>
                user.name?.toLowerCase().includes(value) || user.username?.toLowerCase().includes(value)
            );
            setFilteredFollowing(filtered);
        }
    };

    return (
        <>
                {following.length === 0 ? (<></>) : (
                    <Flex justifyContent="space-between" alignItems="center" mb={5}>
                        <Input
                            placeholder="Search users..."
                            value={searchText}
                            onChange={handleSearch}
                            width="300px"
                        />
                    </Flex>
                )}
                    {following.length === 0 ? (
                            <EmptyState id="no_following" defaultMessage="You are not following anyone yet..."/>
                    ) : (
                        <Grid
                            key={filteredFollowing.length}
                            templateColumns={{ base: "repeat(2, 1fr)", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
                            gap="24px"
                        >
                            {/* Render filteredFollowing users here */}
                            {filteredFollowing.map((u) => (
                                <UserBox
                                    key={u.username}
                                    user={u}
                                    isSelected={false}
                                    onSelect={() => {navigate(`/user/${u.id}`)}}
                                />
                            ))}
                        </Grid>
                    )}
        </>
    );
};

export default FollowingUsers;
