import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {signOutUser} from "../store/auth/authSlice";
import {store} from "../store/store";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/',
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        debugger;
        if (error.response?.status === 401) {
            // Dispatch logout action or clear user state
            store.dispatch(signOutUser());

            // Redirect to the login page
            const navigate = useNavigate();
            navigate('/login');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
