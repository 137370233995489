import {
    Card,
    CardBody,
    CardHeader,
    Flex,
    Grid,
    Input,
    useColorModeValue,
} from "@chakra-ui/react";
import React, {FC, useEffect, useState} from "react";

import {useAppSelector} from "../../../hooks/useAppSelector";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {Source} from "../../../types";
import {RootState} from "../../../store/store";
import {fetchSources, fetchSourcesForUser, modifyUserSources} from "../../../store/sources/sourceSlice";
import SourceBox from "../../../components/SourceBox";
import { useIntl } from "react-intl";
import {useNavigate} from "react-router-dom";

interface FollowingOrganisationsProps {}
const FollowingOrganisations: FC<FollowingOrganisationsProps> = () => {
    const dispatch = useAppDispatch();
    const [searchText, setSearchText] = useState<string>("");
    const [filteredSources, setFilteredSources] = useState<Source[]>([]);
    const selectedSources = useAppSelector((state) => state.source.selectedSources);
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const intl = useIntl();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchSourcesForUser(locale));
    }, [dispatch]);

    useEffect(() => {
        if (searchText === "") {
            setFilteredSources(selectedSources);
        } else {
            const filtered = selectedSources.filter((source) =>
                source.name?.toLowerCase().includes(searchText) || source.code?.toLowerCase().includes(searchText)
            );
            setFilteredSources(filtered);
        }
    }, [selectedSources, searchText]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        if (value === "") {
            setFilteredSources(selectedSources);
        } else {
            const filtered = selectedSources.filter((source) =>
                source.name?.toLowerCase().includes(value) || source.code?.toLowerCase().includes(value)
            );
            setFilteredSources(filtered);
        }
    };

    const handleSourceSelect =  (source: Source) => {
        navigate(`/organisation/${source.id}`)
    };

    return (
        <>
                <Flex justifyContent="space-between" alignItems="center" mb={5}>
                    <Input
                        placeholder={intl.formatMessage({ id: "search_orgs", defaultMessage: "Search organisations..." })}
                        value={searchText}
                        onChange={handleSearch}
                        width="300px"
                    />
                </Flex>
                <Grid
                    key={filteredSources.length} // Forces a re-render whenever filteredSources changes
                    templateColumns={{ base: "repeat(1fr, 1fr)", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
                    gap="24px"
                >
                    {filteredSources.map((s) => (
                        <SourceBox
                            key={s.id}
                            source={s}
                            onSelectTag={handleSourceSelect}
                        />
                    ))}
                </Grid>
        </>
    );
};
export default FollowingOrganisations;