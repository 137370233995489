import React from 'react';
import { AuthUser } from "aws-amplify/auth";
import HeadlinesCardView from "../../components/Feed/HeadlinesCardView";

interface EconomyProps {
    user?: AuthUser
    signOut?: any
}

const Economy = ({user, signOut}: EconomyProps) => (
    <>
                 <HeadlinesCardView category="ECONOMY"/>
    </>
);

export default Economy;