import React, { useEffect, useState } from 'react';
import { AuthUser } from 'aws-amplify/auth';
import HeadlinesCardView from '../../components/Feed/HeadlinesCardView';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import {Box, SimpleGrid, useColorModeValue} from '@chakra-ui/react';
import axios from 'axios';
import NewsCard from "../../components/NewsCard/NewsCard";
import {useParams} from "react-router-dom";

interface SubCategoryNewsListProps {
    user?: AuthUser;
    signOut?: any;
}

const SubCategoryNewsList = ({ user, signOut }: SubCategoryNewsListProps) => {
    const { subCategory } = useParams<{ subCategory: string }>();
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(false);
    const locale = useAppSelector((state) => state.locale.locale);
    const textColor = useColorModeValue('light.text', 'dark.text');
    const hoverColor = useColorModeValue('gray.600', 'gray.400');
    const idToken = useAppSelector((state) => state.auth.idToken);
    const currentRegion = useAppSelector((state) => state.region.currentRegion)?.code || 'ALL';

    useEffect(() => {
        const fetchNews = async () => {
            setLoading(true);
            try {
                const headers = idToken ? { Authorization: `Bearer ${idToken}`, 'Accept-Language': locale } : {};
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/feedBySubCategory/${subCategory}/${currentRegion}`,
                    {
                        headers,
                        params: { page: 0, size: 100 },
                    }
                );

                setNews(response.data.content);
            } catch (error) {
                console.error('Failed to fetch news:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, [subCategory, locale]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Box>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} marginTop={5}>
                {news.length ? (
                    news.map((item, index) => (
                        <Box key={index}>
                            <NewsCard news={item} noOfLines={3} imageHeight={'300'} />
                        </Box>
                    )
                    )
                ) : (
                    <div>No news available</div>
                )}
            </SimpleGrid>
        </Box>
    );
};

export default SubCategoryNewsList;
