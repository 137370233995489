import {createSlice, createAsyncThunk, PayloadAction, AsyncThunk} from "@reduxjs/toolkit";
import { fetchAuthHeaders } from "../../utils/authHeaders";
import {News} from "../../types";
import {RootState} from "../store";
import {startLoading, stopLoading} from "../loading/loadingSlice";
import axiosInstance from "../../utils/axiosInstance";

interface BookmarkState {
    items: { [newsId: string]: boolean };
    isLoaded: boolean;
}

const initialState: BookmarkState = {
    items: {}, // e.g., { "news_id1": true, "news_id2": true }
    isLoaded: false,
};

export const fetchBookmarks: AsyncThunk<News[], void, { state: RootState }> =
    createAsyncThunk(
        "bookmark/fetchBookmarks",
        async (_, { dispatch, getState, rejectWithValue }) => {
            try {
                dispatch(startLoading());
                const headers = await fetchAuthHeaders(dispatch, getState);
                const response = await axiosInstance.get(`api/v1/user/bookmarks`, {headers,});
                return response.data as News[];
            } catch (error: any) {
                return rejectWithValue(
                    error.response?.data?.message || "Failed to fetch bookmarks"
                );
            } finally {
                dispatch(stopLoading());
            }
        }
    );

// Async Thunks for API calls
export const bookmarkNews = createAsyncThunk(
    "bookmark/bookmarkNews",
    async ({ newsId }: { newsId: string | number }, { dispatch, getState, rejectWithValue }) => {
        const headers = await fetchAuthHeaders(dispatch, getState);
        const response = await axiosInstance.post(
            `api/v1/news/bookmark`,
            { news_id: newsId },
            { headers }
        );
        return { newsId, data: response.data };
    }
);

export const unbookmarkNews = createAsyncThunk(
    "bookmark/unbookmarkNews",
    async ({ newsId }: { newsId: string | number }, { dispatch, getState, rejectWithValue }) => {
        const headers = await fetchAuthHeaders(dispatch, getState);
        const response = await axiosInstance.post(
            `api/v1/news/unbookmark`,
            { news_id: newsId },
            { headers }
        );
        return { newsId, data: response.data };
    }
);

// Slice
const bookmarkSlice = createSlice({
    name: "bookmark",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(bookmarkNews.fulfilled, (state, action: PayloadAction<{ newsId: string | number }>) => {
                state.items[action.payload.newsId] = true; // Mark news item as bookmarked
            })
            .addCase(unbookmarkNews.fulfilled, (state, action: PayloadAction<{ newsId: string | number }>) => {
                state.items[action.payload.newsId] = false; // Mark news item as unbookmarked
            })
            .addCase(fetchBookmarks.fulfilled, (state, action: PayloadAction<News[]>) => {
                action.payload.forEach((news) => {
                    state.items[news.id] = true;
                });
                state.isLoaded = true;
        });
    },
});

export default bookmarkSlice.reducer;
