import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Input,
    Flex,
    SimpleGrid,
    Stack
} from '@chakra-ui/react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Checklist from "@editorjs/checklist";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import useCustomToast from "../../hooks/useCustomToast";
import InterestPicker from "../InterestPicker";
import CountryPicker from "../CountryPicker";
import CategoryPicker from "../CategoryPicker";
import FileUpload from "../FileUpload";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import { publishPost } from "../../store/post/postSlice";
import "./editor.css";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useAppSelector} from "../../hooks/useAppSelector";
import {Category, Country, Tag} from "../../types";

const EditorComponent = () => {
    const editorRef = useRef<EditorJS | null>(null);
    const dispatch = useAppDispatch();
    const toast = useCustomToast();
    const toastRef = useRef(toast); // Use ref for stable `toast` function
    const intl = useIntl();
    const { user } = useAppSelector((state) => state.user);

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [tags, setTags] = useState<Tag[]>([]);
    const [category, setCategory] = useState<Category>();
    const [country, setCountry] = useState<Country>();
    const [imageUrl, setImageUrl] = useState('');
    const titleRef = useRef<HTMLInputElement | null>(null);  // Ref for title input to keep focus


    useEffect(() => {
        toastRef.current = toast; // Update toast reference on changes
    }, [toast]);

    const initializeEditor = useCallback(() => {
        if (editorRef.current) return;

        editorRef.current = new EditorJS({
            holder: 'drafteditorjs',
            tools: {
                // @ts-ignore
                header: { class: Header },
                checklist: { class: Checklist },
                quote: { class: Quote },
                marker: { class: Marker },
            },
            onChange: async (api) => {
                const data = await api.saver.save();
                console.log('Data:', data);
                setContent(JSON.stringify(data));
            },
        });
    }, []);

    const handleUploadSuccess = useCallback(
        debounce((fileUrl) => {
            setImageUrl(fileUrl);
            toastRef.current({
                title: 'File uploaded successfully!',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'bottom-right',
            });
        }, 300),
        []
    );

    const handleTagSelection = useCallback((selectedTags: { label: string; value: Tag }[]) => {
        const tagValues = selectedTags.map((option) => option.value);
        setTags(tagValues);
    }, []);

    useEffect(() => {
        initializeEditor();
        if (user) {
            setCountry(user.country);
        }
    }, [initializeEditor, user]);

    const handleSave = async () => {
        if (editorRef.current) {
            try {
                const savedData = await editorRef.current.save();
                setContent(JSON.stringify(savedData));

                await dispatch(
                    publishPost({
                        title,
                        content,
                        imageUrl,
                        country,
                        category,
                        tags,
                        formatting: "MARKDOWN",
                    })
                ).unwrap();

                toast({
                    title: 'Success',
                    description: 'Post Saved Successfully',
                    status: 'success',
                    position: 'bottom-right',
                });
            } catch (error) {
                console.error('Saving error:', error);
            }
        }
    };

    // Maintain focus on title input after state update
    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.focus();  // Ensure the title input retains focus
        }
    }, [title]);  // Re-run when title changes

    return (
        <SimpleGrid columns={{ base: 1, md: 1 }} mr={10} ml={10}>
            <Box>
                <Stack direction="row" spacing={4}>
                    <FileUpload onUploadSuccess={handleUploadSuccess} />
                </Stack>
                <Input
                    placeholder={intl.formatMessage({ id: "news_post_title", defaultMessage: "New post title here..." })}
                    fontSize="2xl"
                    fontWeight="bold"
                    variant="unstyled"
                    py={6}
                    textAlign="left"
                    ref={titleRef}  // Add ref to the title input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <Box p={5} borderWidth={1} borderRadius="md">
                    <div id="drafteditorjs"></div>
                    <Flex mt={6}>
                        <Button colorScheme="blue" onClick={handleSave}>
                            Publish
                        </Button>
                    </Flex>
                </Box>
                <Box mt={4}>
                    <InterestPicker
                        onChange={handleTagSelection}
                        selectedTags={tags}
                        variant="unstyled"
                        placeholder={intl.formatMessage({ id: "add_four_tags", defaultMessage: "Add tags..." })}
                    />
                </Box>
                <Box mt={4}>
                    <Flex gap={4}>
                        <CountryPicker onChange={setCountry} selectedCountryCode={country?.cca2} />
                        <CategoryPicker onChange={setCategory} selectedCategoryCode={category?.code} />
                    </Flex>
                </Box>
            </Box>
            {/*<Box ml={5}>*/}
            {/*    <Heading textAlign="left" mb={5}>*/}
            {/*        <FormattedMessage id="preview" defaultMessage="Preview" />*/}
            {/*    </Heading>*/}
            {/*    <Preview*/}
            {/*        newsData={{*/}
            {/*            id: 0,*/}
            {/*            title,*/}
            {/*            content,*/}
            {/*            imageUrl,*/}
            {/*            tags,*/}
            {/*            country,*/}
            {/*            category,*/}
            {/*            description: "",*/}
            {/*            author: user?.name || "",*/}
            {/*            authorIconUrl: user?.profilePicUrl || "",*/}
            {/*            sourceName: "",*/}
            {/*            link: "",*/}
            {/*            sourceIconUrl: "",*/}
            {/*            language: "",*/}
            {/*            publishedAt: getCurrentDate(locale),*/}
            {/*            formatting: "MARKDOWN",*/}
            {/*            type: "USER_POST",*/}
            {/*            status: "DRAFT",*/}
            {/*        }}*/}
            {/*     isBookmarked={false} isUserPost={true}/>*/}
            {/*</Box>*/}
        </SimpleGrid>
    );
};

export default EditorComponent;
