import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Box,
    Heading,
    Text,
    Divider
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import React from "react";

interface TermsOfUseDrawerProps {
    isOpen: boolean;
    onClose: () => void;
}

const TermsOfUseDrawer: React.FC<TermsOfUseDrawerProps> = ({ isOpen, onClose }) => {
    return (
        <Drawer isOpen={isOpen} onClose={onClose} placement="bottom" size="full">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader />
                <DrawerBody>
                    <Box textAlign="justify" p={4} pl={16} pr={16} mx="auto" width={['100%', '50%']}>
                        <Heading as="h2" size="lg" mb={4}>
                            <FormattedMessage id="termsOfUse" defaultMessage="Terms of Use" />
                        </Heading>

                        {/* Section 1: Introduction */}
                        <Text mb={4}>
                            <FormattedMessage
                                id="termsOfUseIntro"
                                defaultMessage="By accessing and using our website and services, you agree to comply with these Terms of Use. Please read these terms carefully before using our services."
                            />
                        </Text>
                        <Divider my={4} />

                        {/* Section 2: User Responsibilities */}
                        <Heading as="h3" size="md" mb={2}>
                            <FormattedMessage id="userResponsibilities" defaultMessage="User Responsibilities" />
                        </Heading>
                        <Text mb={4}>
                            <FormattedMessage
                                id="termsOfUseUserResponsibilities"
                                defaultMessage="As a user, you are responsible for ensuring that your use of the website and services complies with applicable laws and regulations. You agree not to misuse or abuse the service or attempt to gain unauthorized access to our systems."
                            />
                        </Text>
                        <Divider my={4} />

                        {/* Section 3: Privacy and Data Usage */}
                        <Heading as="h3" size="md" mb={2}>
                            <FormattedMessage id="privacyDataUsage" defaultMessage="Privacy and Data Usage" />
                        </Heading>
                        <Text mb={4}>
                            <FormattedMessage
                                id="termsOfUsePrivacy"
                                defaultMessage="We take your privacy seriously. By using our services, you consent to the collection and use of your data as described in our Privacy Policy. We are committed to protecting your personal information."
                            />
                        </Text>
                        <Divider my={4} />

                        {/* Section 4: Prohibited Activities */}
                        <Heading as="h3" size="md" mb={2}>
                            <FormattedMessage id="prohibitedActivities" defaultMessage="Prohibited Activities" />
                        </Heading>
                        <Text mb={4}>
                            <FormattedMessage
                                id="termsOfUseProhibitedActivities"
                                defaultMessage="You agree not to engage in activities that could harm, disrupt, or interfere with the operation of our website and services. This includes, but is not limited to, hacking, spamming, and distributing malicious software."
                            />
                        </Text>
                        <Divider my={4} />

                        {/* Section 5: Limitation of Liability */}
                        <Heading as="h3" size="md" mb={2}>
                            <FormattedMessage id="limitationOfLiability" defaultMessage="Limitation of Liability" />
                        </Heading>
                        <Text mb={4}>
                            <FormattedMessage
                                id="termsOfUseLiability"
                                defaultMessage="Our liability is limited to the fullest extent permitted by law. We are not responsible for any indirect, incidental, or consequential damages arising from your use of our services."
                            />
                        </Text>
                        <Divider my={4} />

                        {/* Section 6: Governing Law */}
                        <Heading as="h3" size="md" mb={2}>
                            <FormattedMessage id="governingLaw" defaultMessage="Governing Law" />
                        </Heading>
                        <Text mb={4}>
                            <FormattedMessage
                                id="termsOfUseGoverningLaw"
                                defaultMessage="These Terms of Use shall be governed by and construed in accordance with the laws of the jurisdiction in which our company is based, without regard to its conflict of law principles."
                            />
                        </Text>
                    </Box>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default TermsOfUseDrawer;
