import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Text, Heading,
} from '@chakra-ui/react';
import {FormattedMessage, useIntl} from "react-intl";

const ContactUsDrawer: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
    const intl = useIntl();
    return (
        <Drawer isOpen={isOpen} onClose={onClose} placement="bottom" size="full">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader/>

                <DrawerBody>
                    <Box textAlign="justify" p={4} pl={16} pr={16} mx="auto" width={['100%', '50%']}>
                    <Heading as="h2" size="lg" mb={4}>
                            <FormattedMessage id="contactUs" defaultMessage="Contact Us" />
                        </Heading>
                    </Box>
                    <Box textAlign="justify" p={4} pl={16} pr={16} mx="auto" width={['100%', '50%']}>
                    <Text mb={6} fontSize="lg" textAlign="justify">
                            <FormattedMessage
                                id="contactDescription"
                                defaultMessage="We’d love to hear from you! Whether you have a question, feedback, or just want to say hello, feel free to reach out to us using the form below, and we’ll get back to you as soon as possible."
                            />
                        </Text>

                        {/* Name Field */}
                        <FormControl id="name" mb={4}>
                            <FormLabel>
                                <FormattedMessage id="nameLabel" defaultMessage="Name" />
                            </FormLabel>
                            <Input
                                type="text"
                                placeholder={intl.formatMessage({ id: "select_your_country", defaultMessage: "Your Name" })}
                                required
                            />
                        </FormControl>

                        {/* Email Field */}
                        <FormControl id="email" mb={4}>
                            <FormLabel>
                                <FormattedMessage id="emailLabel" defaultMessage="Email" />
                            </FormLabel>
                            <Input
                                type="email"
                                placeholder={intl.formatMessage({ id: "emailPlaceholder", defaultMessage: "Your Email" })}
                                required
                            />
                        </FormControl>

                        {/* Message Field */}
                        <FormControl id="message" mb={6}>
                            <FormLabel>
                                <FormattedMessage id="messageLabel" defaultMessage="Message" />
                            </FormLabel>
                            <Textarea
                                placeholder={intl.formatMessage({ id: "messagePlaceholder", defaultMessage: "Your Message" })}
                                rows={6}
                                required
                            />
                        </FormControl>

                        {/* Submit Button */}
                        <Button colorScheme="teal" type="submit" mb={4}>
                            <FormattedMessage id="submitButton" defaultMessage="Submit" />
                        </Button>

                        {/* Additional Contact Info */}
                        <Text mt={6} fontSize="lg" textAlign="center">
                            <FormattedMessage
                                id="additionalContactInfo"
                                defaultMessage="Alternatively, you can reach us through the following:"
                            />
                        </Text>
                        <Box mt={4}>
                            <Text fontWeight="bold">
                                <FormattedMessage id="emailInfo" defaultMessage="Email: support@company.com" />
                            </Text>
                            <Text>
                                <FormattedMessage id="phoneInfo" defaultMessage="Phone: +1 (123) 456-7890" />
                            </Text>
                            <Text>
                                <FormattedMessage id="addressInfo" defaultMessage="Address: 123 Company St., City, Country" />
                            </Text>
                        </Box>
                    </Box>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default ContactUsDrawer;
