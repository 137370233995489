import React, { useEffect, useState } from 'react';
import {
    Box, VStack, HStack, Text, Button, Fade, Card, useColorModeValue, Flex, useBreakpointValue, CardBody
} from '@chakra-ui/react';
import Projects from "./components/Projects";
import FollowingTags from "./components/FollowingTags";
import Followers from "./components/Followers";
import FollowingUsers from "./components/FollowingUsers";
import FollowingOrganisations from "./components/FollowingOrganisations";
import { useAppSelector } from "../../hooks/useAppSelector";
import { fetchUserDetails } from "../../store/user/userSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { RootState } from "../../store/store";
import { FormattedMessage } from "react-intl";
import Bookmarks from "./components/Bookmarks";
import { useLocation, useNavigate } from "react-router-dom";

const Posts = () => <Text fontSize="xl">Posts Content</Text>;

const menuItems = [
    { key: "posts", label: 'Posts', component: () => <Projects /> },
    { key: "bookmarks", label: 'Bookmarks', component: () => <Bookmarks /> },
    { key: "followers", label: 'Followers', component: () => <Followers /> },
    { key: "following_users", label: 'Following users', component: FollowingUsers },
    { key: "following_tags", label: 'Following tags', component: FollowingTags },
    { key: "following_orgs", label: 'Following organizations', component: FollowingOrganisations },
];

const Profile = () => {
    const dispatch = useAppDispatch();
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const location = useLocation();
    const navigate = useNavigate();

    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const [selectedMenu, setSelectedMenu] = useState(() => {
        const hash = location.hash.replace('#', '');
        return menuItems.find(item => item.key === hash)?.label || 'Posts';
    });

    const { user } = useAppSelector((state) => state.user);

    useEffect(() => {
        dispatch(fetchUserDetails(locale));
    }, [dispatch, locale]);

    useEffect(() => {
        const selectedKey = menuItems.find(item => item.label === selectedMenu)?.key;
        if (selectedKey) {
            navigate(`#${selectedKey}`, { replace: true });
        }
    }, [selectedMenu, navigate]);

    const SelectedComponent = menuItems.find(item => item.label === selectedMenu)?.component || Posts;

    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <Box marginTop={5} width="100%"  maxWidth="100vw"
             overflowX="hidden">
            <Flex
                justifyContent="center"
                gap="2%"
                margin="auto"
                width="100%"
                direction={{ base: "column", md: "row" }} // Column for mobile, row for desktop
                wrap="wrap"
            >
                {/* Sidebar - Mobile */}
                <Box
                    width="100%" // Full width for mobile
                    display={{ base: "block", md: "none" }} // Show only on mobile

                >
                    <Card
                        borderRadius="lg"
                        position="sticky"
                        top="1%"
                        p={4}
                        bgColor={bgColor}
                    >
                        <HStack
                            as="ul"
                            overflowX="auto"
                            spacing={4}
                            listStyleType="none"
                            p={2}
                            m={0}
                            whiteSpace="nowrap" // Ensure items don't wrap
                        >
                            {menuItems.map(({ key, label }, index) => (
                                <Box key={index} as="li" flexShrink={0}>
                                    <Button
                                        variant="ghost"
                                        fontWeight={label === selectedMenu ? 'bold' : 'normal'}
                                        onClick={() => setSelectedMenu(label)}
                                        bg={label === selectedMenu ? 'black' : 'transparent'}
                                        color={label === selectedMenu ? 'white' : 'black'}
                                        _hover={{ bg: label === selectedMenu ? 'black' : 'gray.200' }}
                                        textAlign="center"
                                    >
                                        <FormattedMessage id={key} defaultMessage={label} />
                                    </Button>
                                </Box>
                            ))}
                        </HStack>
                    </Card>
                </Box>


                {/* Sidebar for Desktop */}
                <Box
                    display={{ base: "none", md: "block" }} // Hide sidebar on mobile, show on desktop
                    width={{ base: "100%", md: "20%" }}
                    alignSelf="flex-start"
                >
                    <Card
                        borderRadius="lg"
                        position="sticky"
                        top="1%"
                        p={4}
                        bgColor={bgColor}
                    >
                        <Box as="ul" display="flex" flexDirection="column" p={0} m={0} listStyleType="none">
                            {menuItems.map(({ key, label }, index) => (
                                <Box key={index} as="li" pt={index === 0 ? 0 : 2}>
                                    <Button
                                        variant="ghost"
                                        fontWeight={label === selectedMenu ? 'bold' : 'normal'}
                                        justifyContent="start"
                                        onClick={() => setSelectedMenu(label)}
                                        bg={label === selectedMenu ? 'black' : 'transparent'}
                                        color={label === selectedMenu ? 'white' : 'black'}
                                        _hover={{ bg: label === selectedMenu ? 'black' : 'gray.200' }}
                                        width="100%"
                                        textAlign="left"
                                    >
                                        <FormattedMessage id={key} defaultMessage={label} />
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    </Card>
                </Box>

                {/* Selected Component */}
                <Box width={{ base: "100%", md: "75%" }} marginTop={{ base: 5, md: 0 }}>
                    <Fade in>
                        <Card p='16px' bgColor={bgColor}>
                            <CardBody px='5px'>
                                <SelectedComponent />
                            </CardBody>
                        </Card>
                    </Fade>
                </Box>
            </Flex>

        </Box>
    );
};

export default Profile;
