import { extendTheme} from "@chakra-ui/react";

const colors = {
    light: {
        background: "#ffffff",    // Main content background
        secondaryText: "#756c69", // Secondary text or muted elements
        border: "#b0aba9",       // Borders or dividers
        mutedText: "#949494",     // Less emphasized text
        accent: "#af958a",        // Accent color for buttons/highlights
        secondaryBackground: "#d3cfcd", // Secondary sections or components
        inputBorder: "#c4c4c4",   // Form fields or input borders
        cardBackground: "#ffffff", // Card background or less prominent elements
        sidebarFooterBackground: "#e7dcd3", // Sidebars or footers
        highlight: "#c08c94",     // Call-to-action buttons or important highlights
        text: "#000000",       // Dark text
        primary: "#E94B3C",    // Red highlight color (for links, etc.)
        footerBackground: "#f4f4f4", // Slightly darker background for Footer in light mode
    },
    dark: {
        background: "#2c2c2c",    // Main content background
        secondaryText: "#c3c3c3", // Secondary text or lighter elements
        border: "#c3c3c3",       // Borders or dividers
        accent: "#b6675b",       // Accent color for buttons/highlights
        mutedText: "#949494",     // Less emphasized text
        inputBorder: "#8c8c8c",   // Form fields or input borders
        secondaryBackground: "#5c5c5c", // Secondary sections or components
        cardBackground: "#1f1f1f", // Card background or less prominent elements
        sidebarFooterBackground: "#78473f", // Sidebars or footers
        highlight: "#52302a",     // Call-to-action buttons or important highlights
        gradientStart: "#272727", // Start color for dark gradient
        gradientEnd: "#2A2A2A",
        text: "#E0E0E0",       // Light text
        primary: "#E94B3C",    // Red highlight color (for links, etc.)
        footerBackground: "#1f1f1f", // Slightly darker background for Footer in dark mode
    },
    brand: {
        50: "#ffe5e5",
        100: "#fbb8b8",
        200: "#f38b8b",
        300: "#ec5e5e",
        400: "#e53e3e", // Your primary brand color (red swoosh)
        500: "#c03434",
        600: "#8f2626",
        700: "#5e1919",
        800: "#2e0c0c",
        900: "#000000",
    },
    gray: {
        50: "#f7fafc",
        100: "#edf2f7",
        200: "#e2e8f0",
        300: "#cbd5e0",
        400: "#a0aec0",
        500: "#718096",
        600: "#4a5568",
        700: "#2d3748",
        800: "#1a202c",
        900: "#171923",
    },
};

const toastTheme = {
    baseStyle: {
        container: {
            fontFamily: "'Roboto', sans-serif", // Replace with the font you want
            borderRadius: "md",
            boxShadow: "lg",
            padding: "1rem",
        },
        title: {
            fontWeight: "bold",
        },
        description: {
            marginTop: "0.5rem",
        },
    },
    variants: {
        success: {
            container: {
                bg: "#38A169", // Success background
                color: "#FFFFFF", // Text color
            },
        },
        error: {
            container: {
                bg: "#E53E3E", // Error background
                color: "#FFFFFF", // Text color
            },
        },
        warning: {
            container: {
                bg: "#DD6B20", // Warning background
                color: "#FFFFFF", // Text color
            },
        },
        info: {
            container: {
                bg: "#3182CE", // Info background
                color: "#FFFFFF", // Text color
            },
        },
        light: {
            container: {
                bg: "#f5f5f5", // Light background
                color: "#333333", // Dark text color
            },
        },
        dark: {
            container: {
                bg: "linear-gradient(135deg, #2D3748 0%, #1A202C 100%)", // Dark background with gradient
                color: "#E2E8F0", // Light text color
            },
        },
    },
};

const config = {
    initialColorMode: "light",
    useSystemColorMode: false,
};

const theme = extendTheme({
    colors,
    config,
    fonts: {
        heading: "Roboto, sans-serif",
        body: "Roboto, sans-serif",
    },
    styles: {
        global: (props: any) => ({
            body: {
                bg: props.colorMode === "dark"
                    ? `linear-gradient(180deg, ${colors.dark.gradientStart} 0%, ${colors.dark.gradientEnd} 100%)`
                    : colors.light.background,
                color: props.colorMode === "dark" ? colors.dark.text : colors.light.text,
                fontSize: { base: "md", md: "lg" }, // Default font size for body
                lineHeight: "tall",
                textAlign: 'justify',
            },
            p: {
                fontSize: { base: "md", md: "lg" }, // Default font size for paragraph tags
            },
        }),
    },
    components: {
        Toast: toastTheme,
        Button: {
            baseStyle: {
                fontWeight: "bold", // Optional: Ensures consistent font weight
            },
            variants: {
                solid: {
                    bg: "black", // Background color
                    color: "white", // Text color
                    _hover: {
                        bg: "gray.700", // Darker background on hover
                    },
                },
            },
            defaultProps: {
                variant: "solid", // Sets the default variant for buttons
            },
        },
    },
});

export default theme;
