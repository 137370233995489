import { ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import { store } from './store/store'
import { Provider } from 'react-redux'
import * as ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter as Router } from 'react-router-dom'
import * as serviceWorker from "./serviceWorker"
import {IntlProvider} from "react-intl";
import './index.css';

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

const locale = localStorage.getItem('locale') || navigator.language.split(/[-_]/)[0];

const loadMessages = async (locale: string) => {
    switch (locale) {
        case 'fr':
            return (await import('./locales/fr')).default;
        case 'de':
            return (await import('./locales/de')).default;
        case 'hi':
            return (await import('./locales/hi')).default;
        case 'en':
        default:
            return (await import('./locales/en')).default;
    }
};

loadMessages(locale).then(messages => {
    root.render(
        // <React.StrictMode>
            <IntlProvider locale={locale} messages={messages}>
                <Provider store={store}>
                    <Router>
                        <ColorModeScript/>
                        <App/>
                    </Router>
                </Provider>
            </IntlProvider>
        // </React.StrictMode>,
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

