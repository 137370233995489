import React, {FC, useEffect, useMemo, useState} from 'react';
import {Box, Heading, HStack, Icon, Link, SimpleGrid, useColorModeValue} from '@chakra-ui/react';
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../store/store";
import { groupBy } from 'lodash';
import { useAppDispatch } from "../../hooks/useAppDispatch";
import NewsCard from "../NewsCard/NewsCard";
import { fetchCategoryFeedNews } from "../../store/categoryNewsFeed/categoryNewsFeedSlice";
import {ChevronRightIcon} from "@chakra-ui/icons";


interface HeadlinesProps {
    category: string;
    numLines?: number;
}

const HeadlinesCardView: FC<HeadlinesProps> = ({ category, numLines = 2 }: HeadlinesProps) => {
    const news = useAppSelector((state: RootState) => state.categoriesNewsFeed.data[category] || []);
    const dispatch = useAppDispatch();
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const textColor = useColorModeValue('light.text', 'dark.text');
    const [isHovered, setIsHovered] = useState(false);
    const hoverColor = useColorModeValue('gray.600', 'gray.400');
    // Fetch category feed news on mount
    useEffect(() => {
        dispatch(fetchCategoryFeedNews({ category, locale, page: 0 }));
    }, [dispatch, category, locale]);

    // Memoize grouped and ungrouped lists
    const { mainList, groupedBySubCategory, remainingList } = useMemo(() => {
        const ungroupedListItems = news.filter((item) => item.subCategoryDisplay === null);
        const grouped = groupBy(news.filter((item) => item.subCategoryDisplay !== null), (item) => `${item.subCategoryDisplay}__${item.subCategory}`);

        Object.keys(grouped).forEach((key) => {
            if (grouped[key].length > 4) {
                const [topItems, extraItems] = [grouped[key].slice(0, 4), grouped[key].slice(4)];
                grouped[key] = topItems;
                ungroupedListItems.push(...extraItems);
            }
        });

        return {
            mainList: ungroupedListItems.slice(0, 9),
            remainingList: ungroupedListItems.slice(9),
            groupedBySubCategory: grouped,
        };
    }, [news]);

    return (
        <Box>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} marginTop={5}>
                {mainList.map((item, index) => (
                    <Box key={index}>
                        <NewsCard news={item} noOfLines={numLines} imageHeight={'300'} />
                    </Box>
                ))}
            </SimpleGrid>

            {Object.keys(groupedBySubCategory).map((subCategory, index) => {
                const [subCategoryDisplay, subCategoryCode] = subCategory.split('__');
              return  (
                    <Box key={index}>
                        <Link
                            href={`/news/subcategory/${subCategoryCode}`}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            _hover={{textDecoration: "none"}}
                        >
                            <HStack spacing={2} align="center">
                                <Heading
                                    as="h2"
                                    fontSize="2xl"
                                    borderLeftWidth="6px"
                                    borderLeftColor={isHovered ? hoverColor : textColor}
                                    color={isHovered ? hoverColor : textColor}
                                    pl={4}
                                    marginTop={10}
                                    marginBottom={5}
                                >
                                    {subCategoryDisplay}
                                    {isHovered && (
                                        <Icon
                                            as={ChevronRightIcon}
                                            w={7}
                                            h={7}
                                            ml={2}
                                            fontWeight="bold"
                                        />
                                    )}
                                </Heading>
                            </HStack>
                        </Link>
                        <SimpleGrid columns={{base: 1, md: 4}} spacing={10}>
                            {groupedBySubCategory[subCategory].map((item, idx) => (
                                <Box key={idx}>
                                    <NewsCard news={item} noOfLines={numLines} imageHeight={'300'}/>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>
                )
            })}

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} marginTop={5}>
                {remainingList.slice(0, 12).map((item, index) => (
                    <Box key={index}>
                        <NewsCard news={item} noOfLines={numLines} imageHeight={'300'} />
                    </Box>
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default React.memo(HeadlinesCardView);
