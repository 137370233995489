import React from "react";
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
} from "@chakra-ui/react";
import PrivacyPolicy from "../PrivacyPolicy";

interface PrivacyPolicyDrawerProps {
    isOpen: boolean;
    onClose: () => void;
}

const PrivacyPolicyDrawer: React.FC<PrivacyPolicyDrawerProps> = ({ isOpen, onClose }) => {
    return (
        <Drawer isOpen={isOpen} onClose={onClose} placement="bottom" size="full">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader />
                <DrawerBody>
                    <PrivacyPolicy/>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default PrivacyPolicyDrawer;
