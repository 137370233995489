import React from 'react';
import { Box, Container, Heading, Text, Link, VStack, HStack, Divider, List, ListItem, ListIcon } from '@chakra-ui/react';

const PrivacyPolicy = () => {
    return (
        <Container maxW="container.md" textAlign={"justify"} my={5}>
            <VStack spacing={6}>
                <Heading as="h1" size="xl" textAlign="center" fontWeight="bold">
                    Privacy Policy
                </Heading>

                <Text>
                    This privacy policy explains our policy regarding the collection, use, disclosure and transfer of your information by NiSEE Pvt Ltd. and/or its subsidiary(ies) and/or affiliate(s) (collectively referred to as the "Company"), which operates various websites and other services including but not limited to delivery of information and content via any mobile or internet connected device or otherwise (collectively the "Services"). This privacy policy forms part and parcel of the Terms of Use for the Services. Capitalized terms which have been used here but are undefined shall have the same meaning as attributed to them in the Terms of Use.
                </Text>

                <Text>
                    As we update, improve and expand the Services, this policy may change, so please refer back to it periodically. By accessing the Company website or this Application or otherwise using the Services, you consent to collection, storage, and use of the personal information you provide (including any changes thereto as provided by you) for any of the services that we offer.
                </Text>

                <Text fontWeight="bold" fontSize="1.5rem">
                    1. INFORMATION RECEIVED, COLLECTED AND STORED BY THE COMPANY
                </Text>

                <VStack align="start" spacing={4}>

                    <Heading as="h5" size="sm" fontWeight="semibold">
                        A. INFORMATION SUPPLIED BY USERS
                    </Heading>

                    <Text fontWeight="bold">Registration data:</Text>
                    <Text>
                        When you register on the website, Application, and for the Service, we ask that you provide basic contact Information such as your name, sex, age, address, pin code, contact number, occupation, interests and email address etc. When you register using your other accounts like on Facebook, Twitter, Gmail etc. we shall retrieve Information from such account to continue to interact with you and to continue providing the Services.
                    </Text>

                    <Text fontWeight="bold">Subscription or paid service data:</Text>
                    <Text>
                        When you choose any subscription or paid service, we or our payment gateway provider may collect your purchase, address or billing information, including your credit card number and expiration date etc. However, when you order using an in-app purchase option, the same is handled by such platform providers. The subscriptions or paid Services may be on auto-renewal mode unless cancelled. If at any point you do not wish to auto-renew your subscription, you may cancel your subscription before the end of the subscription term.
                    </Text>

                    <Text fontWeight="bold">Voluntary information:</Text>
                    <Text>
                        We may collect additional information at other times, including but not limited to when you provide feedback, change your content or email preferences, respond to a survey, or communicate with us.
                    </Text>

                    <Heading as="h5" size="sm" fontWeight="semibold">
                        B. INFORMATION AUTOMATICALLY COLLECTED/ TRACKED WHILE NAVIGATION
                    </Heading>

                    <Text fontWeight="bold">Cookies:</Text>
                    <Text>
                        To improve the responsiveness of the "Application" for our users, we may use "cookies", or similar electronic tools to collect information to assign each visitor a unique, random number as a User Identification (User ID) to understand the user's individual interests using the identified computer.
                    </Text>

                    <Text fontWeight="bold">Opting out:</Text>
                    <Text>
                        If a user opts out using the Ads Settings, the unique DoubleClick cookie ID on the user's browser is overwritten with the phrase "OPT_OUT". Because there is no longer a unique cookie ID, the opt-out cookie can't be associated with a particular browser.
                    </Text>

                    <Text fontWeight="bold">Log File Information:</Text>
                    <Text>
                        We automatically collect limited information about your computer's connection to the Internet, mobile number, including your IP address, when you visit our site, application or service. Your IP address is a number that lets computers attached to the Internet know where to send you data -- such as the pages you view.
                    </Text>

                    <Text fontWeight="bold">Clear GIFs:</Text>
                    <Text>
                        We may use "clear GIFs" (Web Beacons) to track the online usage patterns of our users in an anonymous manner, without personally identifying the user. We may also use clear GIFs in HTML-based emails sent to our users to track which emails are opened by recipients.
                    </Text>

                    <Text fontWeight="bold">Information from other Sources:</Text>
                    <Text>
                        We may receive information about you from other sources, add it to our account information and treat it in accordance with this policy. If you provide information to the platform provider or other partner, whom we provide services, your account information and order information may be passed on to us.
                    </Text>

                    <Text fontWeight="bold">Demographic and purchase information:</Text>
                    <Text>
                        We may reference other sources of demographic and other information in order to provide you with more targeted communications and promotions. We use Google Analytics, among others, to track the user behavior on our website.
                    </Text>

                </VStack>

                <Text fontWeight="bold" fontSize="1.5rem">
                    2. LINKS TO THIRD PARTY SITES / AD-SERVERS
                </Text>
                <Text>
                    The Application may include links to other websites or applications. Such websites or applications are governed by their respective privacy policies, which are beyond our control. Once you leave our servers (you can tell where you are by checking the URL in the location bar on your browser), use of any information you provide is governed by the privacy policy of the operator of the application, you are visiting.
                </Text>

                <Text fontWeight="bold" fontSize="1.5rem">
                    3. INFORMATION USE BY THE COMPANY
                </Text>
                <Text>
                    The Information as supplied by the users enables us to improve the Services and provide you the most user-friendly experience. In some cases/provision of certain service(s) or utility(ies), we may require your contact address as well.
                </Text>

                <Text fontWeight="bold" fontSize="1.5rem">
                    4. INFORMATION SHARING
                </Text>
                <Text>
                    The Company shares your information with any third party without obtaining the prior consent of the User in the following limited circumstances:
                </Text>

                <List spacing={3}>
                    <ListItem>
                        <ListIcon color="green.500" />
                        When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences.
                    </ListItem>
                    <ListItem>
                        <ListIcon color="green.500" />
                        The Company proposes to share such information within its group companies and officers and employees of such group companies for the purpose of processing personal information on its behalf.
                    </ListItem>
                    <ListItem>
                        <ListIcon color="green.500" />
                        The Company may present information to our advertisers in the form of aggregated statistics on traffic to various pages within our site.
                    </ListItem>
                    <ListItem>
                        <ListIcon color="green.500" />
                        The Company may share your information regarding your activities on websites or Applications with third party social websites.
                    </ListItem>
                </List>

                <Text fontWeight="bold" fontSize="1.5rem">
                    5. ACCESSING AND UPDATING PERSONAL INFORMATION
                </Text>
                <Text>
                    When you use the Services Site (or any of its sub sites), we make good faith efforts to provide you, as and when requested by you, with access to your personal information and shall further ensure that any personal information or sensitive personal data or information found to be inaccurate or deficient shall be corrected or amended as feasible.
                </Text>

            </VStack>
        </Container>
    );
};

export default PrivacyPolicy;
