import React from 'react';
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Box, Text, Heading } from '@chakra-ui/react';
import { FormattedMessage } from "react-intl";

const AboutUsDrawer: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {

    // The static content as React elements
    const aboutUsContent = (
        <>
            <Heading as="h2" size="lg" mb={4}>
                <FormattedMessage id="ourStory" defaultMessage="Our Story" />
            </Heading>
            <Text mb={4}>
                <FormattedMessage
                    id="ourStoryContent"
                    defaultMessage="A year back on a coffee morning at Gurgaon, a few Marketing and Technology enthusiasts were going through Newspaper headlines and stumbled upon the idea of curating a News medium which stays independent of prejudices and offers equal opportunity for every individual to say their say while taking cognizance of the happenings around. Started with a vague idea it took months to picture it and slowly it started taking shape and thus the idea of NiSEE was born."
                />
            </Text>
            <Heading as="h2" size="lg" mb={4}>
                <FormattedMessage id="birthOfNisee" defaultMessage="Birth of NiSEE" />
            </Heading>
            <Text mb={4}>
                <FormattedMessage
                    id="birthOfNiseeContent"
                    defaultMessage="NiSEE, as now you heard the story, stands for News I See curated with a purpose to offer authentic and unbiased news to every reader along with an opportunity to create stories which we can read, share, ponder upon, save and analyze to build perspectives unlike mainstream media which unfortunately frightens you than making you informed."
                />
            </Text>
            <Heading as="h2" size="lg" mb={4}>
                <FormattedMessage id="ourOffering" defaultMessage="Our Offering" />
            </Heading>
            <Text mb={4}>
                <FormattedMessage
                    id="ourOfferingContent"
                    defaultMessage="NiSEE is carefully designed to remain extremely user-friendly as we truly believe that user interface has to be minimalistic and professional enough to build engagement. The moment you come onboard at NiSEE you will experience an unmatched quality along with a unique concept of fresh & unbiased news."
                />
            </Text>
            <Text mb={4}>
                <FormattedMessage
                    id="exploreNisee"
                    defaultMessage="So, what are we waiting for! Let's explore NiSEE and experience News as it is with no frills attached, no more exaggerations, no favours, no biases, and only true insights into incidents."
                />
            </Text>
            <Text mb={4}>
                <FormattedMessage id="comeExperience" defaultMessage="Come, Let's experience the new way to create, share and read News." />
            </Text>
            <Text>
                <FormattedMessage id="welcomeToNisee" defaultMessage="Welcome to NiSEE!" />
            </Text>
        </>
    );

    return (
        <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} size="full">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader></DrawerHeader>
                <DrawerBody>
                    <Box textAlign="justify" p={4} pl={16} pr={16} mx="auto" width={['100%', '50%']}>
                    <Heading as="h2" size="lg" mb={4}>
                            <FormattedMessage id="aboutUs" defaultMessage="About Us" />
                        </Heading>
                    </Box>
                    <Box textAlign="justify" p={4} pl={16} pr={16} mx="auto" width={['100%', '50%']}>
                    {aboutUsContent}
                    </Box>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default AboutUsDrawer;
