import React, {useEffect} from 'react';
import {Box, Text, Divider, VStack} from '@chakra-ui/react';
import { RootState} from "../../store/store";
import { News } from '../../types';
import {fetchLatestNews} from "../../store/latestNews/latestNewsSlice";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useNavigate} from "react-router-dom";

const LatestNews = () => {
    const dispatch = useAppDispatch();
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const news = useAppSelector((state: RootState) => state.latestNews.data);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchLatestNews({ locale, page: 0 }));
    }, [dispatch, locale]);

    return (
        <Box mx="auto">
            <VStack align="stretch" marginTop={5} >
                {news.map((item: News, index: number) => (
                    <React.Fragment key={index}>
                        <Text
                            noOfLines={4}
                            onClick={() => navigate(`/news/${item.id}`)}
                            fontSize={{ base: "sm", md: "md" }}
                            textAlign="justify"
                            _hover={{ textDecoration: "underline" }}
                        >
                            {item?.title}
                        </Text>
                        {index < news.length - 1 && (
                            <Divider borderColor="gray.300" />
                            )}
                    </React.Fragment>
                ))}
            </VStack>

        </Box>
    );
};

export default LatestNews;
