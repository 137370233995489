import {
    Box,
    Divider,
    Grid,
    GridItem,
    Heading, HStack, Icon, Image,
    Link,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import React, {useState} from "react";
import { NewsByCategory } from "../../types";
import fallbackImage from '../../assets/img/placeholder.png';
import {ChevronRightIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";

interface NewsByCategoryDisplayProps {
    category: NewsByCategory;
}

const NewsByCategoryDisplay = ({ category }: NewsByCategoryDisplayProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const hoverColor = useColorModeValue('gray.600', 'gray.400');
    const textColor = useColorModeValue('light.text', 'dark.text');
    const secondaryTextColor = useColorModeValue('light.secondaryText', 'dark.secondaryText');
    const navigate = useNavigate();
    return (
        <Box key={category.categoryCode} mb={12}>
            <Link
                href={`/news/${category.redirectUrl}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                _hover={{ textDecoration: "none" }}
            >
                <HStack spacing={2} align="center">
                    <Heading
                        as="h2"
                        fontSize="2xl"
                        borderLeftWidth="6px"
                        borderLeftColor={isHovered ? hoverColor : textColor }
                        color={isHovered ? hoverColor : textColor}
                        display="flex"
                        alignItems="center"
                        pl={4}
                    >
                        {category.category}
                        {isHovered && (
                            <Icon
                                as={ChevronRightIcon}
                                w={7}
                                h={7}
                                ml={2}
                                fontWeight="bold"
                            />
                        )}
                    </Heading>
                </HStack>
            </Link>

            <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={6} mt={5}>
                {/* Featured news (first item) */}
                <GridItem colSpan={2}>
                    {category.content[0] && (
                        <Box>
                            <Image
                                src={category.content[0].imageUrl}
                                alt={category.content[0].title}
                                borderRadius="md"
                                w="100%"
                                h="100%"
                                fallbackSrc={fallbackImage}
                                title={category.content[0].title}

                            />
                            <Heading size="md" mb={2} textAlign="left">
                                <Link href={category.content[0].link} isExternal>
                                    {category.content[0].title}
                                </Link>
                            </Heading>
                        </Box>
                    )}
                </GridItem>

                {/* Smaller articles list */}
                <GridItem>
                    <Box>
                        {category.content.slice(1).map((news) => (
                            <Box mb={{ base: 2, md: 6 }} key={news.id}>
                                        <Text
                                            noOfLines={3}
                                            fontSize={{ base: "md", md: "md" }}
                                            textAlign="left"
                                            onClick={() => navigate(`/news/${news.id}`)}
                                            _hover={{ textDecoration: "underline" }}
                                        >
                                            {news.title}
                                        </Text>
                                <Divider size="xl" variant="thick" colorScheme="red" />
                            </Box>
                        ))}
                    </Box>
                </GridItem>
            </Grid>
        </Box>
    );
};

export default NewsByCategoryDisplay;
