import Header from "@editorjs/header";
import Checklist from "@editorjs/checklist";
import Quote from "@editorjs/quote";
import Warning from "@editorjs/warning";
import Marker from "@editorjs/marker";
import CodeTool from "@editorjs/code";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import LinkTool from "@editorjs/link";

export const editorConfig =  (content: string, readonly: boolean) => {
   return {
       holder: 'editorjs',
       data: JSON.parse(content), // Initialize Editor.js with JSON content
       tools: {
           header: Header,
           checklist: {
               // @ts-ignore
               class: Checklist,
               inlineToolbar: true,
           },

           quote: {
               class: Quote,
               inlineToolbar: true,
               config: {
                   quotePlaceholder: 'Enter a quote',
                   captionPlaceholder: 'Quote\'s author',
               },
               shortcut: 'CMD+SHIFT+O'
           },

           warning: Warning,

           marker: {
               class:  Marker,
               shortcut: 'CMD+SHIFT+M'
           },

           code: {
               class:  CodeTool,
               shortcut: 'CMD+SHIFT+C'
           },

           delimiter: Delimiter,

           inlineCode: {
               class: InlineCode,
               shortcut: 'CMD+SHIFT+C'
           },

           linkTool: LinkTool

       },
       readOnly: readonly,
   }
}